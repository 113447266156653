import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {MatchStatus} from "data/enums";
import {first, last} from "lodash";

export interface IMatch {
	id: number;
	status: MatchStatus;
	startDate: string;
	endDate: string;
}

// TODO: This store will be remove
export interface IMatchesStore {
	get getIsLoading(): boolean;

	get list(): IMatch[];

	get scheduleMatches(): IMatch[];

	get currentMatch(): IMatch | undefined;

	get selectedMatch(): IMatch | undefined;

	fetchMatches(): Promise<void>;
}

@injectable()
export class MatchesStore implements IMatchesStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IMatch[] = [];

	get list() {
		return this._list;
	}

	get scheduleMatches() {
		return this._list.filter((e) => e.status === MatchStatus.Scheduled);
	}

	get completedMatches() {
		return this._list.filter((e) => e.status === MatchStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentMatch() {
		return this.activeMatch || first(this.scheduleMatches) || last(this.list);
	}

	get selectedMatch() {
		return first(this.scheduleMatches) || last(this.list);
	}

	private get activeMatch() {
		return this.list.find((e) => e.status === MatchStatus.Playing);
	}

	@action
	async fetchMatches() {
		const {data} = await this._jsonProvider.matches();

		runInAction(() => {
			this._list = data;
		});
	}
}
