import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {TUTORIAL_KEY} from "data/constants";

export interface IModalTutorialController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get step(): number;
	get showIndicators(): boolean;
	get totalSteps(): number;
	close: () => void;
	previous: () => void;
	next: () => void;
	goToStartStep: () => void;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable _step: number = 0;
	_totalSteps: number = 6;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	get step() {
		return this._step;
	}

	get totalSteps() {
		return this._totalSteps;
	}

	get showIndicators() {
		return this._step !== 0;
	}

	public close = () => {
		localStorage.setItem(TUTORIAL_KEY, "true");
		this._modalsStore.hideModal();
		this._step = 0;
	};

	public previous = () => {
		if (this._step > 1) {
			this._step--;
		}
	};

	public next = () => {
		if (this._step < this._totalSteps) {
			this._step++;
		} else {
			this.close();
		}
	};

	public goToStartStep = () => {
		this._step = 1;
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
