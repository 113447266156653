import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {includes} from "lodash";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

interface IController {
	pathname: string;
}

export interface IMenuItemsController extends ViewController<IController> {
	i18n: ILocalizationStore;
	get isLeaguesPage(): boolean;
	get isAuthorized(): boolean;
	get isMobileMenuOpen(): boolean;
	openTutorial: () => void;
	openMobileMenu: () => void;
	closeMobileMenu: () => void;
	logout: () => void;
}

@injectable()
export class MenuItemsController implements IMenuItemsController {
	@observable private _isLeaguesPage = false;
	@observable private _isOpenMobileMenu = false;
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}
	get isLeaguesPage() {
		return this._isLeaguesPage;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get isMobileMenuOpen() {
		return this._isOpenMobileMenu;
	}

	@action onChange({pathname}: IController) {
		this._isLeaguesPage = pathname ? includes(pathname, "league/") : false;
	}

	init({pathname}: IController) {
		this._isLeaguesPage = includes(pathname, "league/");
	}

	@action openTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
		this.closeMobileMenu();
	};

	@action closeMobileMenu = () => {
		runInAction(() => {
			this._isOpenMobileMenu = false;
		});
	};

	@action openMobileMenu = () => {
		runInAction(() => {
			this._isOpenMobileMenu = true;
		});
	};

	@action logout = () => {
		this.closeMobileMenu();
		void this._userStore.logout();
	};
}
