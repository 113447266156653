import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {MatchStatus, ModalType, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEqual} from "lodash";
import {type IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface ITeamBarController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isTeamSaveDisabled(): boolean;
	get isTeamResetDisabled(): boolean;
	get isLoading(): boolean;
	get isLockout(): boolean;
	get isSaved(): boolean;

	onSave: () => void;
	onReset: () => void;
}

@injectable()
export class TeamBarController implements ITeamBarController {
	@observable _requestState = RequestState.IDLE;

	get isTeamSaveDisabled() {
		return this._teamStore.isTeamSaveDisabled || this.isLoading;
	}

	get isTeamResetDisabled() {
		// this._requestState = RequestState.IDLE;
		return !this._teamStore.lineupIds.find((item) => item) || this.isLoading;
	}

	get isLoading(): boolean {
		return isEqual(this._requestState, RequestState.PENDING) || this._playersStore.getIsLoading;
	}

	get isLockout() {
		return this._gameweeksStore.selectedMatch?.status !== MatchStatus.Scheduled;
	}

	get isSaved() {
		return this._requestState === RequestState.SUCCESS && !this._teamStore.isTeamChanged;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._modalsStore.showModal(ModalType.TEAM_SAVED_CONFIRMATION);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action onSave = () => {
		this._requestState = RequestState.PENDING;
		const matchId = this._gameweeksStore?.selectedMatch?.id || null;

		if (matchId) {
			void this._teamStore
				.saveTeam(matchId, this._userStore.user?.displayName || "")
				.then(this.onSuccess)
				.catch(this.onError);
		}
	};

	@action onReset = () => {
		this._teamStore.resetTeam();
	};
}
