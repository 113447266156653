import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {
	Box,
	Drawer,
	AccordionDetails,
	List,
	ListItem,
	AccordionSummary,
	css,
	Accordion,
} from "@mui/material";
import {IMenuItemsController} from "./menu_items.controller";
import {useIsMobile} from "data/hooks";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import {ReactComponent as ArrowDropUpIcon} from "assets/img/icons/arrowDropUp.svg";
import {useLocation} from "react-router";
import {ReactComponent as MenuRounded} from "assets/img/icons/menuRounded.svg";

const ToggleMobileMenu = styled.button`
	border: none;
	background: transparent;
`;

const primaryLinkStyles = css`
	position: relative;
	display: flex;
	align-items: center;
	padding: 20px 0 20px 25px;
	background: transparent;
	border: none;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	text-transform: uppercase;
	border-bottom: 1px solid #d2d2d2;
	width: 100%;
	color: #19194b;
`;

const RightBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	width: 24px;
	height: 24px;
	flex: 1;

	.MuiDrawer-modal {
		margin-top: 70px !important;
	}

	a.active {
		background: transparent;
	}
`;

const MenuWrapper = styled.ul`
	a,
	button {
		${primaryLinkStyles}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 25px;
			content: "";
			display: block;
			width: calc(100% - 19px);
			height: 10px;
			transform: translateX(-50%);
			transition: background-color 0.25s linear;
		}

		&:hover,
		&.active {
			background: #fece00;
			&::after {
				width: 62px;
			}
		}
	}
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	&.MuiAccordionDetails-root {
		padding: 0;
	}

	> * .MuiListItem-root a {
		padding: 13px 24px;
	}
`;

const StyledList = styled(List)`
	padding: 0;

	ul {
		& .MuiListItem-root {
			background: #eae9ff;

			a {
				padding: 13px 30px;
			}
		}
	}
`;

const StyledListItem = styled(ListItem)`
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #191a1a;
	padding: 0;

	a,
	button {
		width: 100%;
		padding: 13px 16px 13px 40px !important;
	}

	& .MuiAccordionSummary-root {
		background-color: #f3f3f9 !important;
	}

	&:has(.MuiAccordion-root) {
		padding: 0;
	}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
	height: 48px;
	${primaryLinkStyles}

	.MuiAccordionSummary-expandIconWrapper {
		padding: 0;
		margin-right: 8px;
	}

	.MuiAccordionSummary-expandIconWrapper svg {
		width: 32px;
		height: 32px;
		color: #191a1a;
	}

	svg {
		transform: rotate(180deg);
	}

	&.MuiAccordionSummary-root.Mui-expanded {
		min-height: 48px;
		color: #191a1a;
		background: #fff;
		svg {
			color: #191a1a;
		}
	}
	.MuiAccordionSummary-content.Mui-expanded {
		margin: 0;
	}
`;

const ButtonStyled = styled.button`
	${primaryLinkStyles}
`;

export const MobileMenuItems: React.FC = observer(() => {
	const location = useLocation();
	const {isMobile} = useIsMobile();
	const {
		isAuthorized,
		openTutorial,
		i18n,
		openMobileMenu,
		closeMobileMenu,
		isMobileMenuOpen,
		logout,
	} = useViewController<IMenuItemsController>(Bindings.MenuItemsController, {
		pathname: location.pathname,
	});

	console.log(isMobileMenuOpen);

	return isMobile ? (
		<RightBlock className={"mobile-block"}>
			<ToggleMobileMenu>
				<Exist when={!isMobileMenuOpen}>
					<MenuRounded onClick={openMobileMenu} />
				</Exist>
				<Exist when={isMobileMenuOpen}>
					<CloseIcon onClick={closeMobileMenu} />
				</Exist>
			</ToggleMobileMenu>

			<Drawer
				anchor="left"
				open={isMobileMenuOpen}
				onClose={closeMobileMenu}
				sx={{
					".MuiDrawer-paper": {padding: 0, width: "100%", margin: "64px 0 0 0"},
					".MuiModal-backdrop": {margin: "64px 0 0 0"},
				}}>
				<Box
					sx={{
						width: "100%",
						height: "100%",
					}}>
					<MenuWrapper>
						<li>
							<a href="/my-team">{i18n.t("menu.label.my_team", "My Team")}</a>
						</li>
						<Exist when={isAuthorized}>
							<li>
								<a href="/">{i18n.t("menu.label.dashboard", "Dashboard")}</a>
							</li>

							<Fragment>
								<li>
									<Accordion
										sx={{
											m: 0,
											p: 0,
											borderRadius: 0,
											boxShadow: "none",
											width: "100%",
										}}
										disableGutters={true}>
										<StyledAccordionSummary
											sx={{m: 0, p: 0}}
											expandIcon={<ArrowDropUpIcon />}>
											Leagues
										</StyledAccordionSummary>
										<StyledAccordionDetails>
											<StyledList>
												<StyledListItem>
													<a href={`/leagues`} onClick={closeMobileMenu}>
														{i18n.t(
															"menu.label.my_leagues",
															"My Leagues"
														)}
													</a>
												</StyledListItem>
												<StyledListItem>
													<a
														href={`/leagues/create`}
														onClick={closeMobileMenu}>
														{i18n.t(
															"menu.label.create_league",
															"Create a League"
														)}
													</a>
												</StyledListItem>
												<StyledListItem>
													<a
														href={`/leagues/join`}
														onClick={closeMobileMenu}>
														{i18n.t(
															"menu.label.join_league",
															"Join a league"
														)}
													</a>
												</StyledListItem>
											</StyledList>
										</StyledAccordionDetails>
									</Accordion>
								</li>
								<li>
									<a href="/leaderboard" onClick={closeMobileMenu}>
										{i18n.t("menu.label.leaderboard", "Leaderboard")}
									</a>
								</li>
							</Fragment>
						</Exist>

						<li>
							<a href="/help" onClick={closeMobileMenu}>
								{i18n.t("navigation.help", "Help")}
							</a>
						</li>
						<Exist when={isAuthorized}>
							<a href={"/my-account"} onClick={closeMobileMenu}>
								{i18n.t("manu.my_account", "My Account")}
							</a>
						</Exist>
						<li className="last">
							<ButtonStyled onClick={openTutorial}>
								{i18n.t("navigation.tutorial", "Tutorial")}
							</ButtonStyled>
						</li>
						<Exist when={isAuthorized}>
							<li className="last">
								<ButtonStyled onClick={logout}>
									{i18n.t("navigation.log_out", "Log out")}
								</ButtonStyled>
							</li>
						</Exist>
					</MenuWrapper>
				</Box>
			</Drawer>
		</RightBlock>
	) : null;
});
