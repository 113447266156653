import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, reaction} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IMatchesStore} from "data/stores/matches/matches.store";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

export interface ILiveScoreController extends ViewController {
	subscribeLiveScoring: () => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _liveScoreDisposer?: ReturnType<typeof reaction>;

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}
	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.MatchesStore) private _matchesStore: IMatchesStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	/**
	 * Call to subscribe on checksum changes
	 */
	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions = () => {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	/**
	 * Provide object of files you want to update
	 * for example: matches
	 */
	private generateActions(): IChecksumAction {
		return {
			rounds: () => this._matchesStore.fetchMatches(),
		};
	}

	dispose(): void {
		this._liveScoreDisposer?.();
		this.unsubscribeLiveScoring();
	}

	async init() {
		// Fetch checksums
		await this._checksumStore.fetchChecksums();

		// Subscribe on changes
		this._liveScoreDisposer = reaction(() => this.updatedChecksum, this.callActions, {
			fireImmediately: true,
		});
	}
}
