import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {IMatch, IMatchesStore} from "data/stores/matches/matches.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, ReactNode} from "react";
import type {SelectChangeEvent} from "@mui/material";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeaguePrivacy, ModalType, RequestState} from "data/enums";
import {first, identity, isEqual, values} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

export interface ICreateLeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get rounds(): IMatch[];
	get isLoading(): boolean;
	get isCreateButtonDisabled(): boolean;
	get isFormDisabled(): boolean;
	get tmpLeague(): ILeague | null;
	get formValue(): IFormValue;

	handleCreateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	startRoundChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _roundsDisposer?: ReturnType<typeof reaction>;
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _requestState = RequestState.PENDING;
	@observable private _tmpLeague: ILeague | null = null;
	@observable private _formValue: IFormValue = {
		leagueName: "",
		startId: 0,
		privacy: LeaguePrivacy.PRIVATE,
	};

	get rounds() {
		return this._roundsStore.scheduleMatches;
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFormDisabled() {
		return Boolean(this.isLoading || this.tmpLeague);
	}

	get isCreateButtonDisabled() {
		return this.isFormDisabled || !this.isFormValid;
	}

	get tmpLeague() {
		return this._tmpLeague;
	}

	@computed private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.MatchesStore) private _roundsStore: IMatchesStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onSuccessLeagueCreate = (league: ILeague) => {
		this._tmpLeague = league;
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	handleCreateLeague = () => {
		this._requestState = RequestState.PENDING;

		const {leagueName, startId, privacy} = this._formValue;

		console.log(startId);

		this._leaguesStore
			.createLeague({
				privacy,
				// startId,
				name: leagueName,
			})
			.then(this.onSuccessLeagueCreate)
			.then(() => {
				if (this._tmpLeague) {
					this._navigate?.(`/league/${this._tmpLeague.id}`, {replace: true});
				}
			})
			.catch(this.onError);
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action init({navigate}: IProps) {
		this._navigate = navigate;
		this._roundsStore.fetchMatches().then(this.onSuccess).catch(this.onError);

		this._roundsDisposer = reaction(
			() => this.rounds,
			() => {
				const roundID = first(this.rounds)?.id;

				if (roundID) {
					this._formValue.startId = roundID;
				}
			}
		);
	}

	dispose() {
		this._roundsDisposer?.();
	}
}
