import {makeAutoObservable, runInAction, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import React from "react";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IUpdateNotificationController extends ViewController {
	i18n: ILocalizationStore;
	get isUpdateNotificationDisabled(): boolean;

	get notification(): boolean;

	toggleNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
	updateNotification: () => void;
}

@injectable()
export class UpdateNotificationController implements IUpdateNotificationController {
	@observable private _notification: boolean | null = null;
	@observable private _requestStateNotifications = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get user() {
		return this._userStore.user!;
	}

	get isUpdateNotificationDisabled() {
		return (
			this._notification === null || this._notification === this.user.isNotificationsEnabled
		);
	}

	get notification() {
		if (!this.isUpdateNotificationDisabled) {
			return !!this._notification;
		}
		return this.user.isNotificationsEnabled;
	}

	toggleNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._notification = e.currentTarget.checked;
	};

	updateNotification = () => {
		this._requestStateNotifications = RequestState.PENDING;

		this._userStore
			.update({
				isNotificationsEnabled: this.notification,
			})
			.then(() => {
				runInAction(() => {
					this._requestStateNotifications = RequestState.SUCCESS;
					this._notification = null;
				});
			})
			.catch(() => {
				runInAction(() => {
					this._requestStateNotifications = RequestState.SUCCESS;
					this._notification = null;
				});
			});
	};
}
