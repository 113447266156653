import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";

export interface ITeamLineupController extends ViewController {
	readonly i18n: ILocalizationStore;

	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;
}

@injectable()
export class TeamLineupController implements ITeamLineupController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore
	) {
		makeAutoObservable(this);
	}

	get homeSquad() {
		const selectedMatch = this._gameweeksStore.selectedMatch;
		return this._squadsStore.getSquadById(selectedMatch?.homeSquad || 0);
	}

	get awaySquad() {
		const selectedMatch = this._gameweeksStore.selectedMatch;
		return this._squadsStore.getSquadById(selectedMatch?.awaySquad || 0);
	}
}
