import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useIsMobile} from "data/hooks";

const GameNavWrapper = styled.div`
	background: #84180f;
	width: 100%;
	height: 44px;
`;

const GameNavigation = styled.nav`
	display: flex;
	align-items: center;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	gap: 12px;
	height: 100%;
	padding-left: 12px;

	li {
		height: 100%;
		padding: 0;
		list-style: none;
	}

	li:first-of-type {
		margin-left: -10px;
	}

	a,
	button {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		color: #ffffff;
		font-family: var(--fontFamilyBase);
		font-weight: 700;
		font-size: 16px;
		height: 100%;

		&::after {
			position: absolute;
			bottom: 0;
			left: 50%;
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			transform: translateX(-50%);
			transition: background-color 0.25s linear;
		}

		&:hover,
		&.active {
			color: #ffe000;
			&::after {
				background-color: #ffe000;
			}
		}
	}

	.last {
		margin-left: auto;
	}

	@media screen and (max-width: 900px) {
		display: none;
	}
`;

/**
 * Example
 *
 * <SubNavigationMenu>
 * 		<Fragment>
 * 			<li>
 * 				<NavLink to="/team">Manage Team</NavLink>
 * 			</li>
 * 			<li>
 * 				<NavLink to="/leaderboard">Leaderboard</NavLink>
 * 			</li>
 * 		    <li className="last">
 * 				<PureButton>Tutorial</PureButton>
 * 			</li>
 * 		</Fragment>
 * 	</SubNavigationMenu>
 */

interface IProps {
	children: React.ReactNode;
}

export const SubNavigationMenu: React.FC<IProps> = observer(({children}) => {
	const {isMobile} = useIsMobile();

	if (isMobile) {
		return null;
	}
	return (
		<GameNavWrapper>
			<GameNavigation>{children}</GameNavigation>
		</GameNavWrapper>
	);
});
