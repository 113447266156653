import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {NavLink} from "react-router-dom";
import {PureButton} from "views/components/pure_button/pure_button.component";
import {SubNavigationMenu} from "views/components/sub_navigation_menu/sub_navigation_menu";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {useLocation} from "react-router";
import {IMenuItemsController} from "views/components/menu_items/menu_items.controller";
import {ReactComponent as InfoRounded} from "assets/img/icons/InfoRounded.svg";
import styled from "@emotion/styled";

const TutorialButton = styled(PureButton)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffe000 !important;

	svg {
		margin-left: 10px;
	}
`;

export const DesktopMenuItems: React.FC = observer(() => {
	const location = useLocation();

	const {isLeaguesPage, isAuthorized, openTutorial, i18n} =
		useViewController<IMenuItemsController>(Bindings.MenuItemsController, {
			pathname: location.pathname,
		});

	return location.pathname !== "/" ? (
		<SubNavigationMenu>
			<li>
				<NavLink to="/my-team">{i18n.t("menu.label.my_team", "My Team")}</NavLink>
			</li>
			<Exist when={isAuthorized}>
				<Fragment>
					<li>
						<NavLink
							to="/leaderboard"
							title={i18n.t("menu.label.leaderboard", "Leaderboard")}>
							{i18n.t("menu.label.leaderboard", "Leaderboard")}
						</NavLink>
					</li>
					<li>
						<NavLink
							title={i18n.t("sub_nav.label.leagues", "Leagues")}
							to="/leagues"
							className={({isActive}) => (isActive || isLeaguesPage ? "active" : "")}>
							{i18n.t("menu.label.leagues", "Leagues")}
						</NavLink>
					</li>
				</Fragment>
			</Exist>
			<li>
				<NavLink to="/help">Help</NavLink>
			</li>
			<li className="last">
				<TutorialButton className="last" onClick={openTutorial}>
					{i18n.t("menu.label.tutorial", "Tutorial")} <InfoRounded />
				</TutorialButton>
			</li>
		</SubNavigationMenu>
	) : null;
});
