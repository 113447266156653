import styled from "@emotion/styled";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React from "react";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {ContainerPage} from "views/components/structure/structure.component";
import {Box, useMediaQuery} from "@mui/material";
import {NavLink} from "react-router-dom";
import {ReactComponent as LogoImg} from "assets/img/logo.svg";
import {ReactComponent as GeniusLogoIcon} from "assets/img/geniusLogos.svg";
import {MOBILE_VIEW_QUERY} from "data/constants";
import {Exist} from "views/components/exist/exist.component";

const Wrapper = styled.div`
	background: #373737;
`;

const ContainerPageStyled = styled(ContainerPage)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 125px;

	@media screen and (max-width: 960px) {
		flex-direction: column;
		justify-content: center;
		padding: 20px 0;
	}
`;

const Copywriting = styled.div`
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 135%;
	margin-top: 10px;

	@media screen and (max-width: 960px) {
		text-align: center;
		margin: 10px 0;
	}
`;

const PoweredByLogo = styled.a`
	display: flex;
	align-items: center;
	justify-content: end;

	p {
		color: #ffffff;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 135%;
		margin-right: 10px;
	}

	@media screen and (max-width: 960px) {
		justify-content: center;
	}
`;

const Menu = styled.div`
	color: #ffffff;
	margin-bottom: 10px;

	a {
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%;
		text-decoration-line: underline;

		&:first-of-type {
			&:after {
				content: "";
				position: relative;
				top: 3px;
				display: inline-block;
				width: 1px;
				height: 15px;
				margin: 0 10px;
				background: #ffffff;
			}
		}
	}

	@media screen and (max-width: 960px) {
		margin-top: 10px;
	}
`;

const LogoLink = styled(NavLink)`
	display: flex;
	align-items: center;
`;

const LogosWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const isMobileView = useMediaQuery(`(${MOBILE_VIEW_QUERY})`);

	return (
		<Wrapper>
			<ContainerPageStyled>
				<Box>
					<Box>
						<LogosWrapper>
							<LogoLink to={"/"}>
								<LogoImg />
							</LogoLink>
						</LogosWrapper>
						<Exist when={!isMobileView}>
							<Copywriting>
								{i18n.t("footer.copywriting", "© 2024 Genius Sports")}
							</Copywriting>
						</Exist>
					</Box>
				</Box>
				<Box textAlign={"right"}>
					<div>
						<Menu>
							<NavLink to={"/help/terms-and-conditions"}>
								{i18n.t("footer.menu.terms", "Terms & Conditions")}
							</NavLink>
							<NavLink to={"/help/contact-us"}>
								{i18n.t("footer.menu.contact_us", "Contact Us")}
							</NavLink>
						</Menu>
						<PoweredByLogo
							href="https://geniussports.com/"
							target="_blank"
							rel="noopener noreferrer">
							<p>{i18n.t("footer.powered_by:", "Powered By:")}</p> <GeniusLogoIcon />
						</PoweredByLogo>
					</div>
					<Exist when={isMobileView}>
						<Copywriting>
							{i18n.t("footer.copywriting", "© 2024 Genius Sports")}
						</Copywriting>
					</Exist>
				</Box>
			</ContainerPageStyled>
		</Wrapper>
	);
});
