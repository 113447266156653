import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {RequestState, ModalType} from "data/enums";
import type {
	ILeaderboardsStore,
	ILeaderboardItem,
} from "data/stores/leaderboards/leaderboards.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IGameweek, IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";

export interface ILeaderboardsController extends ViewController {
	i18n: ILocalizationStore;
	get isLoading(): boolean;
	get isLoadingMore(): boolean;
	get rankingsList(): ILeaderboardItem[];
	get nextPage(): boolean;
	get userItem(): ILeaderboardItem | null;
	get weeks(): IGameweek[];
	get selectedWeekId(): number;

	loadMoreUsers: () => Promise<void>;
	onChangeWeekId: (weekId: number) => void;
}

@injectable()
export class LeaderboardsController implements ILeaderboardsController {
	@observable private _page = 1;
	@observable private _limit = 50;
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateLoadMore = RequestState.IDLE;
	@observable private _selectedWeekId: number = 0;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardsStore) readonly _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore,
		@inject(Bindings.GameweeksStore) readonly _gameWeeksStore: IGameweeksStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get isLoadingMore(): boolean {
		return this._requestStateLoadMore === RequestState.PENDING;
	}

	get rankingsList(): ILeaderboardItem[] {
		return this._leaderboardsStore.leaderboard.rankings;
	}

	get nextPage(): boolean {
		return this._leaderboardsStore.leaderboard.nextPage;
	}

	get userItem(): ILeaderboardItem | null {
		return this._leaderboardsStore.leaderboard.user;
	}

	get weeks(): IGameweek[] {
		return this._gameWeeksStore.playedGameWeeks;
	}

	get selectedWeekId(): number {
		return this._selectedWeekId;
	}

	init(param: void) {
		void this._fetchLeaderboard();
		void this._gameWeeksStore.fetchGameweeks();
	}

	private _fetchLeaderboard = async () => {
		this._page = 1;
		try {
			runInAction(() => {
				this._requestState = RequestState.PENDING;
			});

			await this.requestLeaderboard();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
		}
	};

	loadMoreUsers = async () => {
		this._page = this._page + 1;
		try {
			runInAction(() => {
				this._requestStateLoadMore = RequestState.PENDING;
			});

			await this.requestLeaderboardMore();

			runInAction(() => {
				this._requestStateLoadMore = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestStateLoadMore = RequestState.ERROR;
			});
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
		}
	};

	private async requestLeaderboard() {
		if (this.selectedWeekId) {
			await this._leaderboardsStore.fetchWeekLeaderboard(this.selectedWeekId, {
				page: this._page,
				limit: this._limit,
			});
			return;
		}
		await this._leaderboardsStore.fetchLeaderboard({
			page: this._page,
			limit: this._limit,
		});
	}

	private async requestLeaderboardMore() {
		if (this.selectedWeekId) {
			await this._leaderboardsStore.fetchWeekLeaderboardMore(this.selectedWeekId, {
				page: this._page,
				limit: this._limit,
			});
			return;
		}

		await this._leaderboardsStore.fetchLeaderboardMore({
			page: this._page,
			limit: this._limit,
		});
	}

	onChangeWeekId = (weekId: number) => {
		this._selectedWeekId = weekId;
		void this._fetchLeaderboard();
	};
}
