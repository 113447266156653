import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {GameweeksStatus, MatchStatus} from "data/enums";
import {first, last, reduce} from "lodash";

export interface IGameweek {
	id: number;
	name: string;
	status: GameweeksStatus;
	startDate: string;
	endDate: string;
	matches: IMatch[];
}

export interface IMatch {
	id: number;
	title: string;
	status: MatchStatus;
	startAt: string;
	endDate: string;
	homeSquad: number;
	awaySquad: number;
	isMarketsReady: boolean;
}

export interface IGameweeksStore {
	get getIsLoading(): boolean;

	get list(): IGameweek[];

	get scheduleGameweeks(): IGameweek[];

	get currentGameweek(): IGameweek | undefined;

	get scoreGameweek(): IGameweek | null;

	get allMatches(): IMatch[];

	get selectedMatch(): IMatch | undefined;

	get selectedGameweek(): IGameweek | undefined;

	get playedGameWeeks(): IGameweek[];

	fetchGameweeks(): Promise<void>;

	setSelectedMatch(matchId: number): void;

	setSelectedGameweek(gameweekId: number): void;
}

@injectable()
export class GameweeksStore implements IGameweeksStore {
	@observable private _isLoading: boolean = false;
	@observable private _selectedMatch: IMatch | undefined = undefined;
	@observable private _selectedGameweek: IGameweek | undefined = undefined;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IGameweek[] = [];

	get list() {
		return this._list.filter((gameweek) => gameweek.matches.length !== 0);
	}

	get scheduleGameweeks() {
		return this._list.filter((e) => e.status === GameweeksStatus.Scheduled);
	}

	get completedGameweeks() {
		return this._list.filter((e) => e.status === GameweeksStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentGameweek() {
		return this.activeGameweek || first(this.scheduleGameweeks) || last(this.list);
	}

	get scoreGameweek() {
		return this.activeGameweek || last(this.completedGameweeks) || null;
	}

	get allMatches() {
		return reduce(
			this._list,
			(result, gameweek) => {
				result = [...result, ...gameweek.matches];
				return result;
			},
			[] as IMatch[]
		);
	}

	get activeMatch() {
		return (
			this.allMatches.find(
				(match) =>
					match.status === MatchStatus.Playing || match.status === MatchStatus.Scheduled
			) || last(this.allMatches)
		);
	}

	get activeMatchInCurrentGameweek() {
		return (
			this._selectedGameweek?.matches.find(
				(match) =>
					(match.status === MatchStatus.Playing ||
						match.status === MatchStatus.Scheduled) &&
					match.isMarketsReady
			) ||
			last(
				this._selectedGameweek?.matches.filter(
					(match) => match.status === MatchStatus.Complete
				)
			) ||
			first(this._selectedGameweek?.matches)
		);
	}

	get selectedMatch() {
		return this._selectedMatch;
	}

	get selectedGameweek() {
		return this._selectedGameweek;
	}

	get playedGameWeeks(): IGameweek[] {
		return this._list.filter((it) =>
			[GameweeksStatus.Playing, GameweeksStatus.Complete].includes(it.status)
		);
	}

	private get activeGameweek() {
		return this.list.find((e) => e.status === GameweeksStatus.Playing);
	}

	@action
	async fetchGameweeks() {
		const {data} = await this._jsonProvider.gameweeks();

		runInAction(() => {
			this._list = data;
			this._selectedGameweek = this.currentGameweek;
			this._selectedMatch = this.activeMatchInCurrentGameweek;
		});
	}

	@action
	setSelectedMatch(matchId: number) {
		const match = this.allMatches.find((match) => match.id === matchId);
		if (match) {
			runInAction(() => {
				this._selectedMatch = match;
			});
		}
	}

	@action
	setSelectedGameweek(gameweekId: number) {
		runInAction(() => {
			this._selectedGameweek = this.list.find((gameweek) => gameweek.id === gameweekId);
			this._selectedMatch = this.activeMatchInCurrentGameweek;
		});
	}
}
