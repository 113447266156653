import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardsProvider} from "data/providers/leaderboards/leaderboards.provider";
import {IPaginationParams} from "data/types/main";

interface ILeagueLeaderboardParams extends IPaginationParams {
	leagueId: number;
	order?: string | null;
	dir?: string | null;
	gameWeek?: number;
}

export interface ILeaderboards {
	rankings: ILeaderboardItem[];
	user: ILeaderboardItem | null;
	nextPage: boolean;
}

export interface ILeaderboardItem {
	userId: number;
	userName: string;
	rank: number;
	points: number;
	teamName: string;
	teamId: number;
	gameWeek?: number;
	overallPoints: number;
}

export interface ILeaderboardsStore {
	get leaderboard(): ILeaderboards;
	fetchLeaderboard: (params: IPaginationParams) => Promise<void>;
	fetchLeaderboardMore: (params: IPaginationParams) => Promise<void>;
	fetchWeekLeaderboard: (weekId: number, params: IPaginationParams) => Promise<void>;
	fetchWeekLeaderboardMore: (weekId: number, params: IPaginationParams) => Promise<void>;
	fetchLeagueLeaderboard: (params: ILeagueLeaderboardParams) => Promise<void>;
}

@injectable()
export class LeaderboardsStore implements ILeaderboardsStore {
	@observable _leaderboard: ILeaderboards = {
		rankings: [],
		user: null,
		nextPage: false,
	};

	constructor(
		@inject(Bindings.LeaderboardsProvider) private _leaderboardsProvider: ILeaderboardsProvider
	) {
		makeAutoObservable(this);
	}

	get leaderboard(): ILeaderboards {
		return this._leaderboard;
	}

	fetchLeaderboard = async (params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.overall(params);
		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchLeaderboardMore = async (params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.overall(params);
		runInAction(() => {
			this._leaderboard = {
				...result.data.success,
				rankings: [...this._leaderboard.rankings, ...result.data.success.rankings],
			};
		});
	};

	fetchWeekLeaderboard = async (weekId: number, params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.week({
			gameWeek: weekId,
			...params,
		});
		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchLeagueLeaderboard = async (params: ILeagueLeaderboardParams) => {
		const result = await this._leaderboardsProvider.league(params);
		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchWeekLeaderboardMore = async (weekId: number, params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.week({
			gameWeek: weekId,
			...params,
		});
		runInAction(() => {
			this._leaderboard = {
				...result.data.success,
				rankings: [...this._leaderboard.rankings, ...result.data.success.rankings],
			};
		});
	};
}
