import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeModal.svg";
import {Exist} from "views/components/exist/exist.component";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {IModalConfirmAlertController} from "views/components/modals/modal_confirm_alert/modal_confirm_alert.controller";
import {ErrorRounded} from "@mui/icons-material";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 64px;

	svg {
		width: 100%;
		height: auto;
		fill: #e40000;
	}
`;

const Title = styled.div`
	color: #0f0f0f;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
`;

const Description = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	max-width: 380px;

	strong {
		font-weight: 700;
	}
`;

const Buttons = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 12px;
	padding-top: 4px;
	button {
		max-width: 135px;
		width: 100%;
		border-radius: 8px;
		min-width: fit-content;
		height: 48px;
		text-transform: none;
	}
`;
export const ModalConfirmAlert: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmAlertController>(
		Bindings.ModalConfirmAlertController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<ErrorRounded />
				</Icon>
				<Title>
					{modalContent?.title
						? modalContent?.title
						: i18n.t("modal.confirm_alert.title", "Are You Sure?")}
				</Title>
				<Exist when={modalContent?.message}>
					<Description
						dangerouslySetInnerHTML={{
							__html: modalContent?.message || "",
						}}
					/>
				</Exist>
				<Stack direction="row" gap={3}>
					<Buttons>
						<Exist when={modalContent?.showCancel}>
							<ButtonPrimary fullWidth variant="contained" onClick={close}>
								{i18n.t("modal.confirm_alert.cancel_button", "No, Cancel")}
							</ButtonPrimary>
						</Exist>
						<Exist when={modalContent?.callback}>
							<Button
								fullWidth
								variant="contained"
								color={"primary"}
								onClick={modalContent?.callback}>
								{modalContent?.confirmButtonCopy ||
									i18n.t("modal.confirm_alert.confirm", "Confirm")}
							</Button>
						</Exist>
					</Buttons>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
