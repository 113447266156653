import React from "react";
import {observer} from "mobx-react";
import {
	Typography,
	Stack,
	Container,
	FilledInput,
	InputLabel,
	InputAdornment,
	Select,
	MenuItem,
} from "@mui/material";
import {PASSWORD_PATTERN} from "data/constants";
import {Checkbox, ErrorText, Label} from "views/components/form";
import {Exist} from "views/components/exist/exist.component";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IRegistrationFormController} from "views/components/registration_form/registration_form.controller";
import styled from "@emotion/styled";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {AuthorizationSection} from "data/enums";
import {PureButton} from "views/components/pure_button/pure_button.component";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {useNavigate} from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import {FormControlStyled} from "views/components/form/styles";

const LoginForm = styled.form`
	width: 100%;
	max-width: 360px;
	margin: 20px auto;

	@media screen and (max-width: 960px) {
		margin: 10px auto;
	}
`;

const Button = styled(PureButton)`
	font-weight: 700;
	color: #0f0f0f;
	text-decoration: underline;
	font-size: 16px;
	font-style: normal;
	line-height: 150%;
`;

const Title = styled.h2`
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
	color: #0f0f0f;
`;

const DateOfBirth = styled.div`
	display: grid;
	justify-content: space-between;
	grid-template-columns: 25% 40% 25%;
`;

const FormStep = styled.div<{isVisible: boolean}>`
	flex-direction: column;
	gap: 24px;
	display: ${({isVisible}) => (isVisible ? "flex" : "none")};

	.MuiStack-root input:checked + svg + span {
		background: #af0000;
		border-color: #6d6d6d;
	}
`;

const StepNavigation = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StepBlock = styled.div<{isActive?: boolean}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 100px;
	background: ${({isActive}) => (isActive ? "#0F0F0F" : "#DBDBDB")};
	color: ${({isActive}) => (isActive ? "#FFFFFF" : "#0F0F0F")};
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
`;
const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 20px;
`;
const StepTitle = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	color: #0f0f0f;
	margin-top: 10px;
`;
const StepSeparator = styled.div`
	position: absolute;
	top: 17px;
	left: 50%;
	display: block;
	height: 1px;
	width: 100px;
	background: #6d6d6d;
	margin-left: -45px;
`;
const ButtonPrimaryStyled = styled(ButtonPrimary)`
	margin: 0 auto;
`;
interface IProps {
	changeAuthorizationSection: (section: AuthorizationSection) => void;
}

export const RegistrationFormComponent: React.FC<IProps> = observer(
	({changeAuthorizationSection}) => {
		const {
			i18n,
			error,
			isFormDisabled,
			handleFormSubmit,
			handleFormOnChange,
			handleValidatePassword,
			countries,
			activeStep,
		} = useViewController<IRegistrationFormController>(Bindings.RegistrationFormController, {
			navigate: useNavigate(),
		});

		const [showPassword, setShowPassword] = React.useState(false);
		const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

		const handleClickShowPassword = () => setShowPassword((show) => !show);
		const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};
		const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		const labelFirstName = i18n.t("registration.email.first_name", "First Name*");
		const labelSurName = i18n.t("registration.email.sur_name", "Last Name*");
		const labelEmail = i18n.t("registration.email.label", "Email");
		const labelConfirmEmail = i18n.t("registration.confirm_email.label", "Confirm Email*");
		const labelPassword = i18n.t("registration.password.label", "Password*");
		const labelConfirmPassword = i18n.t(
			"registration.confirm_password.label",
			"Confirm Password*"
		);
		const labelDisplayName = i18n.t("registration.display_name.label", "Display name*");
		const labelPhoneNumber = i18n.t("registration.phone_number.label", "Phone Number*");
		const labelDay = i18n.t("registration.phone_number.label", "Day");
		const labelYear = i18n.t("registration.phone_number.year", "Year");

		console.log(error);

		return (
			<Container>
				<LoginForm
					onSubmit={handleFormSubmit}
					onChange={handleFormOnChange}
					noValidate={true}>
					<Stack direction="column" gap={3}>
						<Title>{i18n.t("registration.form.title", "Register An Account")}</Title>
						<StepNavigation>
							<StepWrapper>
								<StepBlock isActive={true}>
									<Exist when={activeStep === 2}>
										<DoneIcon />
									</Exist>
									<Exist when={activeStep === 1}>1</Exist>
								</StepBlock>
								<StepTitle>
									{i18n.t("registration.form.step1", "Personal Details")}
								</StepTitle>
							</StepWrapper>
							<StepSeparator />
							<StepWrapper>
								<StepBlock isActive={activeStep === 2}>2</StepBlock>
								<StepTitle>
									{i18n.t("registration.form.step2", "Setup Details")}
								</StepTitle>
							</StepWrapper>
						</StepNavigation>

						<FormStep isVisible={activeStep === 1}>
							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-firstName"
									error={Boolean(error?.firstName)}>
									{labelFirstName}
								</InputLabel>
								<FilledInput
									id={"filled-registration-firstName"}
									disabled={isFormDisabled}
									name="firstName"
									type="text"
									placeholder={labelFirstName}
									required={true}
									error={Boolean(error?.firstName)}
								/>
								<Exist when={error?.firstName}>
									<ErrorText>{error?.firstName}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-surName"
									error={Boolean(error?.surName)}>
									{labelSurName}
								</InputLabel>
								<FilledInput
									id={"filled-registration-surName"}
									disabled={isFormDisabled}
									name="surName"
									type="text"
									placeholder={labelSurName}
									required={true}
									error={Boolean(error?.surName)}
								/>
								<Exist when={error?.surName}>
									<ErrorText>{error?.surName}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-email"
									error={Boolean(error?.email)}>
									{labelEmail}
								</InputLabel>

								<FilledInput
									id={"filled-registration-email"}
									disabled={isFormDisabled}
									name="email"
									type="email"
									placeholder={labelEmail}
									required={true}
									error={Boolean(error?.email)}
								/>
								<Exist when={error?.email}>
									<ErrorText>{error?.email}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-confirmEmail"
									error={Boolean(error?.confirmEmail)}>
									{labelConfirmEmail}
								</InputLabel>
								<FilledInput
									id={"filled-registration-confirmEmail"}
									disabled={isFormDisabled}
									name="confirmEmail"
									type="email"
									placeholder={labelConfirmEmail}
									required={true}
									error={Boolean(error?.confirmEmail)}
								/>
								<Exist when={error?.confirmEmail}>
									<ErrorText>{error?.confirmEmail}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-adornment-password"
									error={Boolean(error?.password)}>
									{labelPassword}
								</InputLabel>
								<FilledInput
									id="filled-adornment-password"
									type={showPassword ? "text" : "password"}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end">
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									disabled={isFormDisabled}
									name="password"
									inputProps={{
										minLength: 8,
										maxLength: 128,
										pattern: PASSWORD_PATTERN,
									}}
									placeholder={labelPassword}
									onChange={handleValidatePassword}
									required={true}
								/>
								<Exist when={error?.password}>
									<ErrorText>{error?.password}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-adornment-confirmPassword"
									error={Boolean(error?.confirmPassword)}>
									{labelConfirmPassword}
								</InputLabel>
								<FilledInput
									id="filled-adornment-confirmPassword"
									type={showConfirmPassword ? "text" : "password"}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowConfirmPassword}
												onMouseDown={handleMouseDownConfirmPassword}
												edge="end">
												{showConfirmPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
									disabled={isFormDisabled}
									name="confirmPassword"
									inputProps={{
										minLength: 8,
										maxLength: 128,
										pattern: PASSWORD_PATTERN,
									}}
									placeholder={labelConfirmPassword}
									required={true}
								/>
								<Exist when={error?.confirmPassword}>
									<ErrorText>{error?.confirmPassword}</ErrorText>
								</Exist>
							</FormControlStyled>
						</FormStep>

						<FormStep isVisible={activeStep === 2}>
							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-displayName"
									error={Boolean(error?.displayName)}>
									{labelDisplayName}
								</InputLabel>
								<FilledInput
									id={"filled-registration-displayName"}
									disabled={isFormDisabled}
									name="displayName"
									type="text"
									placeholder={labelDisplayName}
									required={true}
									error={Boolean(error?.displayName)}
								/>
								<Exist when={error?.displayName}>
									<ErrorText>{error?.displayName}</ErrorText>
								</Exist>
							</FormControlStyled>

							<DateOfBirth>
								<FormControlStyled variant="filled">
									<InputLabel
										htmlFor="filled-registration-day"
										error={Boolean(error?.day)}>
										{labelDay}
									</InputLabel>
									<FilledInput
										id={"filled-registration-day"}
										disabled={isFormDisabled}
										name="day"
										type="number"
										placeholder={labelDay}
										required={true}
										error={Boolean(error?.day)}
									/>
								</FormControlStyled>

								<FormControlStyled variant="filled">
									<InputLabel id="filled-registration-countries">
										Month
									</InputLabel>
									<Select
										id="filled-registration-countries"
										name={"month"}
										required={true}
										defaultValue={""}>
										{[
											"January",
											"February",
											"March",
											"April",
											"May",
											"June",
											"July",
											"August",
											"September",
											"October",
											"November",
											"December",
										].map((month, key) => (
											<MenuItem
												value={key >= 9 ? ++key : `0${++key}`}
												key={key}>
												{month}
											</MenuItem>
										))}
									</Select>
								</FormControlStyled>

								<FormControlStyled fullWidth variant="filled">
									<InputLabel
										htmlFor="filled-registration-year"
										error={Boolean(error?.year)}>
										{labelYear}
									</InputLabel>
									<FilledInput
										id={"filled-registration-year"}
										disabled={isFormDisabled}
										name="year"
										type="number"
										placeholder={labelYear}
										required={true}
										error={Boolean(error?.year)}
									/>
								</FormControlStyled>
							</DateOfBirth>
							<Exist when={error?.dob}>
								<ErrorText>{error?.dob}</ErrorText>
							</Exist>
							<Exist when={error?.day}>
								<ErrorText>{error?.day}</ErrorText>
							</Exist>
							<Exist when={error?.month}>
								<ErrorText>{error?.month}</ErrorText>
							</Exist>
							<Exist when={error?.year}>
								<ErrorText>{error?.year}</ErrorText>
							</Exist>

							<FormControlStyled variant="filled">
								<InputLabel id="filled-registration-countries">
									Country Of Residence*
								</InputLabel>
								<Select
									id="filled-registration-countries"
									name={"country"}
									error={Boolean(error?.country)}
									required={true}
									defaultValue={""}>
									{countries.map((country, key) => (
										<MenuItem value={country.code} key={key}>
											{country.name}
										</MenuItem>
									))}
								</Select>
								<Exist when={error?.country}>
									<ErrorText>{error?.country}</ErrorText>
								</Exist>
							</FormControlStyled>

							<FormControlStyled fullWidth variant="filled">
								<InputLabel
									htmlFor="filled-registration-firstName"
									error={Boolean(error?.phone)}>
									{labelPhoneNumber}
								</InputLabel>
								<FilledInput
									id={"filled-registration-firstName"}
									disabled={isFormDisabled}
									name="phone"
									type="text"
									placeholder={labelPhoneNumber}
									required={true}
									error={Boolean(error?.phone)}
								/>
								<Exist when={error?.phone}>
									<ErrorText>{error?.phone}</ErrorText>
								</Exist>
							</FormControlStyled>

							<Exist when={error?.common}>
								<ErrorText>{error?.common}</ErrorText>
							</Exist>

							<Stack direction="column" gap={2.5}>
								<Stack direction="row" gap={2}>
									<Checkbox
										disabled={isFormDisabled}
										name="terms"
										id="terms"
										required={true}
									/>
									<Label
										htmlFor="terms"
										dangerouslySetInnerHTML={{
											__html: "I have read and accepted the <a href='/help/terms-and-conditions' target='_blank'>Terms & Conditions</a>",
										}}
									/>
								</Stack>
								<Exist when={error?.terms}>
									<ErrorText>{error?.terms}</ErrorText>
								</Exist>
							</Stack>

							<Stack direction="column" gap={2.5}>
								<Stack direction="row" gap={2}>
									<Checkbox
										disabled={isFormDisabled}
										name="marketing"
										id="marketing"
										required={true}
									/>
									<Label htmlFor="marketing">
										{i18n.t(
											"registration.marketing.label",
											"I wish to receive email and marketing."
										)}
									</Label>
								</Stack>
								<Exist when={error?.marketing}>
									<ErrorText>{error?.marketing}</ErrorText>
								</Exist>
							</Stack>
						</FormStep>

						<ButtonPrimaryStyled
							type="submit"
							fullWidth
							variant="contained"
							size="large"
							disabled={isFormDisabled || Boolean(error)}>
							{activeStep === 1
								? i18n.t("registration.form.continue", "Continue")
								: i18n.t("registration.form.submit", "Register")}
						</ButtonPrimaryStyled>

						<Typography textAlign="center">
							{i18n.t("registration.have_account.text", "Already have an account?")}{" "}
							<Button
								onClick={() =>
									changeAuthorizationSection(AuthorizationSection.Login)
								}>
								{i18n.t("registration.sign_in.text", "Sign In")}
							</Button>
						</Typography>
					</Stack>
				</LoginForm>
			</Container>
		);
	}
);
