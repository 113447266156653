import React from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import CloseIcon from "@mui/icons-material/Close";
import {IModalAuthorizationController} from "./modal_authorization.controller";
import {ReactComponent as ModalLogo} from "assets/img/modalLogo.svg";
import {Login} from "views/components/login_form/login_form.component";
import {Exist} from "views/components/exist/exist.component";
import {AuthorizationSection} from "data/enums";
import {RegistrationFormComponent} from "views/components/registration_form/registration_form.component";
import {ForgotPasswordForm} from "views/components/forgot_password_form/forgot_password_form.component";
import {ModalContent} from "views/components/modals/modalStyles";

const CloseBlock = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 50%;

	svg {
		width: 16px;
		fill: #1e1e1e;
	}
`;

const Header = styled.div`
	width: 100%;
	position: relative;
	background: #af0000;
	text-align: center;
	padding: 40px 20px 20px 20px;
	border-radius: 4px 4px 0 0;

	@media screen and (max-width: 960px) {
		svg {
			width: 280px;
		}
	}
`;

const SuccessDescription = styled.div`
	max-width: 360px;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	background: #008805;
	color: #ffffff;
	padding: 15px;
	margin-bottom: 20px;
`;

export const ModalAuthorization: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		close,
		currentAuthorizationSection,
		changeAuthorizationSection,
		modalContent,
	} = useViewController<IModalAuthorizationController>(Bindings.ModalAuthorizationController);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<Header>
					<CloseBlock>
						<Button sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</Button>
					</CloseBlock>
					<ModalLogo />
				</Header>
				<Exist when={currentAuthorizationSection === AuthorizationSection.Login}>
					<Login changeAuthorizationSection={changeAuthorizationSection} />
				</Exist>
				<Exist when={currentAuthorizationSection === AuthorizationSection.Registration}>
					<RegistrationFormComponent
						changeAuthorizationSection={changeAuthorizationSection}
					/>
				</Exist>
				<Exist when={currentAuthorizationSection === AuthorizationSection.ForgotPassword}>
					<ForgotPasswordForm changeAuthorizationSection={changeAuthorizationSection} />
				</Exist>
				<Exist when={modalContent?.isSuccess}>
					<SuccessDescription>
						{i18n.t(
							"reset_password.success_description",
							"Your new password is configured! Please login with your new password."
						)}
					</SuccessDescription>
				</Exist>
			</ModalContent>
		</Modal>
	);
});
