import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {PlayerMarketsCategories, RequestState} from "data/enums";
import {get, find, isEqual} from "lodash";

export interface IPlayerStats {
	ballsFaced: number;
	matches: number;
	numberOf0s: number;
	numberOf4s: number;
	numberOf6s: number;
	overs: number;
	playerId: number;
	runsSc: number;
	score: number;
	strikeRate: number;
	wickets: number;
}

export interface IPlayer {
	firstName: string;
	lastName: string;
	batAvg: number;
	bestBow: string;
	bowAvg: number;
	feedId: number;
	highScore: number;
	id: number;
	matches: number;
	numberOf6s: string;
	overs: number;
	percentSelected: number;
	role: string;
	runsSc: number;
	squadId: number;
	wickets: number;
	lastStatus: string;
	potentialPoints?: number;
	stats?: IPlayerStats;
	lineupPlayerId?: number;
	odds?: number;
	marketName?: PlayerMarketsCategories;
	selection?: number;
	scores?: number;
}

export interface ILineupPlayer extends IPlayer {
	stats: IPlayerStats;
	lineupPlayerId: number;
	odds: number;
	potentialPoints: number;
	selection: number;
	winPos: number | null;
}

export interface ILineupPlayer {
	lineupPlayerId: number;
	odds: number;
	playerId: number;
}

export interface IMarketPlayers {
	marketName: PlayerMarketsCategories;
	lineupPlayers: ILineupPlayer[];
}

export interface IMatchMarketPlayers {
	markets: IMarketPlayers[];
	playersStats: {
		players: IPlayerStats[];
	};
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	get matchMarketPlayers(): IMatchMarketPlayers | null;

	getPlayerById(playerId: number): IPlayer | undefined;
	getPlayerByLineupIdAndMarket(
		lineupPlayerId: number,
		marketCategory: PlayerMarketsCategories
	): ILineupPlayer | undefined;
	fetchPlayers(): Promise<void>;
	fetchMatchMarketPlayers(matchId: number): Promise<void>;
	playersByCurrentMarket(marketCategory: PlayerMarketsCategories): ILineupPlayer[];

	getPlayerByLineupId: (lineupId: number) => IPlayer | undefined;
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _requestState = RequestState.IDLE;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IPlayer[] = [];
	@observable private _matchMarketPlayers: IMatchMarketPlayers | null = null;

	get list() {
		return this._list;
	}

	get matchMarketPlayers() {
		return this._matchMarketPlayers;
	}

	get getIsLoading(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	private calculatePlayerScores(winPos: number | null, odds: number) {
		if (!winPos) {
			return 0;
		}

		// odds * placeMultiplier where placeMultiplier = 10/5/1
		// 10 for 1 position
		// 5 for 2 position
		// 1 for 3 position
		const pointsByPosition = {
			1: 10,
			2: 3,
			3: 1,
		};
		const placeMultiplier = get(pointsByPosition, winPos, null);

		return winPos <= 3 && placeMultiplier ? odds * placeMultiplier : 0;
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	getPlayerByLineupIdAndMarket(
		lineupId: number,
		marketCategory: PlayerMarketsCategories
	): ILineupPlayer | undefined {
		const players = this.playersByCurrentMarket(marketCategory);
		return players.find((player) => player.lineupPlayerId === lineupId);
	}

	getPlayerByLineupId = (lineupId: number) => {
		const market = find(this._matchMarketPlayers?.markets, (market) =>
			market.lineupPlayers.find((lineup) => lineup.lineupPlayerId === lineupId)
		) as IMarketPlayers;
		const playerLineup = market?.lineupPlayers.find(
			(lineup) => lineup.lineupPlayerId === lineupId
		);

		const player = this.getPlayerById(playerLineup?.playerId || 0);

		return player
			? {
					...player,
					marketName: market.marketName,
					...playerLineup,
			  }
			: undefined;
	};

	@action
	async fetchPlayers() {
		this._requestState = RequestState.PENDING;
		const {data} = await this._jsonProvider.players();

		runInAction(() => {
			this._list = data;
			this._requestState = RequestState.SUCCESS;
		});
	}

	@action
	async fetchMatchMarketPlayers(matchId: number) {
		this._requestState = RequestState.PENDING;
		const {data} = await this._jsonProvider.matchMarketPlayers(matchId);

		runInAction(() => {
			this._matchMarketPlayers = data;
			this._requestState = RequestState.SUCCESS;
		});
	}

	playersByCurrentMarket = (marketCategory: PlayerMarketsCategories) => {
		const markets =
			find(
				this._matchMarketPlayers?.markets,
				(market) => market.marketName === marketCategory
			) || (null as IMarketPlayers | null);

		const playersStats = get(this._matchMarketPlayers, `playersStats.players`, null) as
			| IPlayerStats[]
			| null;

		if (markets && playersStats) {
			return markets.lineupPlayers.map((playerLineup) => {
				const player =
					this._list.find((player) => player.id === playerLineup.playerId) || {};
				const playerStats =
					playersStats.find((stats) => stats.playerId === playerLineup.playerId) || {};

				return {
					stats: playerStats,
					lineupPlayerId: playerLineup.lineupPlayerId,
					odds: playerLineup.odds,
					marketName: marketCategory,
					potentialPoints: playerLineup.potentialPoints,
					selection: playerLineup.selection,
					scores: this.calculatePlayerScores(playerLineup.winPos, playerLineup.odds),
					...player,
				} as ILineupPlayer;
			});
		}

		return [];
	};
}
