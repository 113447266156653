import styled from "@emotion/styled";

export const Label = styled.label`
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;

	a {
		text-decoration: underline;
	}
`;

export const ErrorText = styled.p`
	color: var(--invalidColor);
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 18px;
	margin: 10px 10px 10px 20px;
	align-items: flex-start;
	text-align: left;
`;

export * from "views/components/form/input.component";
export * from "views/components/form/checkbox.component";
export * from "views/components/form/radiobutton.component";
export * from "views/components/form/select.component";
