export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	MatchesStore: Symbol("MatchesStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	LoginFormController: Symbol("LoginFormController"),
	RegistrationFormController: Symbol("RegistrationFormController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordFormController: Symbol("ForgotPasswordFormController"),
	ModalResetPasswordController: Symbol("ModalResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	ModalConfirmAlertController: Symbol("ModalConfirmAlertController"),
	LocalizationController: Symbol("LocalizationController"),
	LandingController: Symbol("LandingController"),
	TeamApiProvider: Symbol("TeamApiProvider"),
	TeamStore: Symbol("TeamStore"),
	MenuItemsController: Symbol("MenuItemsController"),
	ComponentsReviewController: Symbol("ComponentsReviewController"),
	MyTeamController: Symbol("MyTeamController"),
	PlayersPoolController: Symbol("PlayersPoolController"),
	PlayerCardController: Symbol("PlayerCardController"),
	TeamLineupController: Symbol("TeamLineupController"),
	TeamPlayerController: Symbol("TeamPlayerController"),
	JoinCreateLeagueCardController: Symbol("JoinCreateLeagueCardController"),
	TeamBarController: Symbol("TeamBarController"),
	DashboardController: Symbol("DashboardController"),
	GameweeksStore: Symbol("GameweeksStore"),
	ModalAuthorizationController: Symbol("ModalAuthorizationController"),
	InvitedLeaguesController: Symbol("InvitedLeaguesController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	LeagueManageController: Symbol("LeagueManageController"),
	ModalTeamSavedController: Symbol("ModalTeamSavedController"),
	GameBarStore: Symbol("GameBarStore"),
	ChangePasswordController: Symbol("ChangePasswordController"),
	UpdateNotificationController: Symbol("UpdateNotificationController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	LeaderboardsController: Symbol("LeaderboardsController"),
	LeaderboardsProvider: Symbol("LeaderboardsProvider"),
	LeaderboardsStore: Symbol("LeaderboardsStore"),
	LeagueTableController: Symbol("LeagueTableController"),
};
