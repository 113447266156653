import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {get, isEqual} from "lodash";
import {type IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import {GameBarStore} from "data/stores/game_bar/game_bar.store";
import {TUTORIAL_KEY} from "data/constants";

interface IProps {
	isMobile: boolean;
}

export interface IMyTeamController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get isOpenPlayerPool(): boolean;
	get isTeamChanged(): boolean;
}

@injectable()
export class MyTeamController implements IMyTeamController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _isMobile = false;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: GameBarStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isOpenPlayerPool() {
		if (this._isMobile) {
			return this._teamStore.isOpenPlayerPool;
		}
		return true;
	}

	get isTeamChanged() {
		return this._teamStore.isTeamChanged;
	}

	@action private onSuccess = () => {
		// const match = this._gameweeksStore.selectedMatch;
		// const squads = this._squadsStore.getSquadsByMatch(match);
		// const selectedSquad = first(squads);
		// const isHome = this._gameweeksStore.selectedMatch?.homeSquad === selectedSquad?.id;
		// this._teamStore.changeSelectedTeam(selectedSquad, isHome);

		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private updateTeamLineup = () => {
		const lineupByMarkets = this._teamStore.lineupByMarkets;
		this._teamStore.lineup.forEach((lineupId) => {
			const player = this._playersStore.getPlayerByLineupId(lineupId);
			if (player?.marketName && get(lineupByMarkets, player.marketName, null) !== null) {
				this._teamStore.addPlayerToLineup(lineupId, player.marketName);
			}
		});
	};

	@action init({isMobile}: IProps) {
		this._isMobile = isMobile;

		void Promise.all([
			this._playersStore.fetchPlayers(),
			this._gameweeksStore.fetchGameweeks(),
			this._squadsStore.fetchSquads(),
		])
			.then(() => {
				if (
					this._gameweeksStore.selectedGameweek?.id &&
					this._gameweeksStore.selectedMatch?.id
				) {
					const selectedMatchId = this._gameweeksStore.selectedMatch.id;
					const selectedGameweekId = this._gameweeksStore.selectedGameweek.id;

					void Promise.all([
						this._playersStore.fetchMatchMarketPlayers(selectedMatchId),
						this._teamStore.fetchTeamByMatchId(selectedMatchId),
						this._gameBarStore.fetchGameBar(selectedGameweekId, selectedMatchId),
					])
						.then(this.onSuccess)
						.catch(() => {
							// Don't show errors
						})
						.finally(this.updateTeamLineup);
				}
				if (localStorage.getItem(TUTORIAL_KEY) !== "true") {
					this._modalsStore.showModal(ModalType.TUTORIAL);
				}
			})
			.catch(this.onError);
	}
}
