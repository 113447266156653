import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IComponentsReviewController extends ViewController {
	readonly i18n: ILocalizationStore;
}

@injectable()
export class ComponentsReviewController implements IComponentsReviewController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {}

	init() {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: "{Text here}",
		});
	}
}
