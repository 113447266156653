import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {MarketsCategory, MatchStatus} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";

export interface IPlayerCardController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isCanAddPlayer(): boolean;
	get isLockout(): boolean;
	get selectedMarketCategory(): MarketsCategory;
	get statsPrefix(): string;

	addPlayerToLineup: (lineupPlayerId: number) => void;
	getPlayerSquad: (squadId: number) => ISquad | undefined;
	checkIsPlayerAddedToTeam: (playerId: number) => boolean;
	removePlayerFromTeam: (playerId: number) => void;
}

@injectable()
export class PlayerCardController implements IPlayerCardController {
	get isCanAddPlayer() {
		return this._teamStore.isCurrentMarkedSelected;
	}

	get isLockout() {
		return this._gameweeksStore.selectedMatch?.status !== MatchStatus.Scheduled;
	}

	get selectedMarketCategory() {
		return this._teamStore.selectedCategory;
	}

	get statsPrefix(): string {
		return this._gameweeksStore.selectedMatch?.status === MatchStatus.Complete ? "stats." : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore
	) {
		makeAutoObservable(this);
	}

	private moveUserToTeamBuilder = () => {
		const element = document.getElementById("teamBuilder");
		element?.scrollIntoView();
	};

	@action
	addPlayerToLineup = (lineupPlayerId: number) => {
		this._teamStore.addPlayerToLineup(lineupPlayerId);
		this._teamStore.togglePlayerPool();
		this.moveUserToTeamBuilder();
	};

	@action
	getPlayerSquad = (squadId: number) => {
		return this._squadsStore.getSquadById(squadId);
	};

	@action
	checkIsPlayerAddedToTeam = (playerId: number) => {
		const lineupPlayers = this._teamStore.lineupIds.map((lineupId) =>
			this._playersStore.getPlayerByLineupIdAndMarket(
				lineupId,
				this._teamStore.playersMarketCategory
			)
		);
		return Boolean(lineupPlayers?.find((player) => player?.id === playerId));
	};

	@action
	removePlayerFromTeam = (playerId: number) => {
		const lineupPlayers = this._teamStore.lineupIds.map((lineupId) =>
			this._playersStore.getPlayerByLineupId(lineupId)
		);

		lineupPlayers?.forEach((player) => {
			if (player?.id === playerId && player.lineupPlayerId) {
				this._teamStore.removeFromLineup(player.lineupPlayerId);
			}
		});

		this.moveUserToTeamBuilder();
	};
}
