import styled from "@emotion/styled";

export const ContainerPage = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;

	@media (max-width: 1430px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

export const PageTitle = styled.h2`
	font-size: 28px;
	line-height: 1.2;
	text-transform: uppercase;
`;
