import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
	IStaticContentPayload,
} from "data/stores/static_content/static_content.store";
import {IMatchMarketPlayers, IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import {IMatch} from "data/stores/matches/matches.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import {IGameweek} from "data/stores/gameweeks/gameweeks.store";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	gameweeks(): Promise<AxiosResponse<IGameweek[]>>;
	matches(): Promise<AxiosResponse<IMatch[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	matchMarketPlayers(matchId: number): Promise<AxiosResponse<IMatchMarketPlayers>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(params: IStaticContentPayload): Promise<AxiosResponse<ICategories>>;
	helpSections(params: IStaticContentPayload): Promise<AxiosResponse<ISections>>;
	helpArticles(params: IStaticContentPayload): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	gameweeks = () => this._jsonClient.get<IGameweek[]>("daily/gameweeks.json");
	matches = () => this._jsonClient.get<IMatch[]>("daily/matches.json");
	players = () => this._jsonClient.get<IPlayer[]>("daily/players.json");
	matchMarketPlayers = (matchId: number) =>
		this._jsonClient.get<IMatchMarketPlayers>(`daily/${matchId}.json`);
	squads = () => this._jsonClient.get<ISquad[]>("daily/squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`locale/${locale}.json`);

	helpCategories = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`, params);
	helpSections = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`, params);
	helpArticles = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`, params);
}
