import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IPlayer, type IPlayersStore} from "data/stores/players/players.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {
	MarketsCategory,
	MatchStatus,
	ModalType,
	PlayerMarketsCategories,
	SortOrder,
} from "data/enums";
import type {SelectChangeEvent} from "@mui/material";
import {ReactNode} from "react";
import {orderBy} from "lodash";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import type {IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IPlayersPoolController extends ViewController {
	readonly i18n: ILocalizationStore;
	get players(): IPlayer[];
	get isShowLoadMore(): boolean;
	get teams(): ISquad[];
	get selectedTeam(): string;
	get selectedCategory(): MarketsCategory;
	get squadCategory(): MarketsCategory[];
	get selectedMarketCategoryInTeam(): PlayerMarketsCategories;
	get isLoading(): boolean;
	get isShowPlayerPoolFilter(): boolean;
	get currentSortBy(): string;
	get currentOrder(): SortOrder;
	get statsPrefix(): string;

	onSelectedTeam: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
	onSelectedCategory: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
	onSortByStat: (stat: string) => void;
	onLoadMore: () => void;
	closePlayerPool: () => void;
	togglePlayerPoolFilter: () => void;
	openTutorial: () => void;
}

@injectable()
export class PlayersPoolController implements IPlayersPoolController {
	@observable _isSessionChecked = false;
	@observable _showLimit = 4;
	@observable _currentSortBy: string = "potentialPoints";
	@observable _currentOrder = SortOrder.ASC;
	@observable _isShowPlayerPoolFilter = true;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get allPlayers() {
		if (this._teamStore.selectedTeam) {
			const selectedTeamId = this._teamStore.isTeamHome
				? this._gameweeksStore.selectedMatch?.homeSquad
				: this._gameweeksStore.selectedMatch?.awaySquad;

			return this._playersStore
				.playersByCurrentMarket(this._teamStore.playersMarketCategory)
				.filter((player) => selectedTeamId === player.squadId);
		}

		return this._playersStore.playersByCurrentMarket(this._teamStore.playersMarketCategory);
	}

	get players() {
		// const sortedPlayers = orderBy(this.allPlayers, [this._currentSortBy], [this._currentOrder]);
		// return sortedPlayers.filter((player, key) => key <= this._showLimit);

		return orderBy(this.allPlayers, [this._currentSortBy], [this._currentOrder]);
	}

	get isShowLoadMore() {
		return this._showLimit < this.allPlayers.length && !this.isLoading;
	}

	get teams() {
		return this._squadsStore.getSquadsByMatch(this._gameweeksStore.selectedMatch);
	}

	get selectedTeam() {
		if (this._teamStore.selectedTeam) {
			const selectedTeamId = this._teamStore.isTeamHome
				? this._gameweeksStore.selectedMatch?.homeSquad
				: this._gameweeksStore.selectedMatch?.awaySquad;
			return selectedTeamId?.toString() || "";
		}

		return "all";
	}

	get selectedCategory() {
		return this._teamStore.selectedCategory;
	}

	get squadCategory() {
		return [MarketsCategory.General, MarketsCategory.Batting, MarketsCategory.Bowling];
	}

	get selectedMarketCategoryInTeam() {
		return this._teamStore.selectedMarketCategoryInTeam;
	}

	get isLoading(): boolean {
		return this._playersStore.getIsLoading;
	}

	get isShowPlayerPoolFilter(): boolean {
		return this._isShowPlayerPoolFilter;
	}

	get currentSortBy(): string {
		return this._currentSortBy;
	}

	get currentOrder(): SortOrder {
		return this._currentOrder;
	}

	get statsPrefix(): string {
		return this._gameweeksStore.selectedMatch?.status === MatchStatus.Complete ? "stats." : "";
	}

	@action onSelectedTeam = (event: SelectChangeEvent<unknown>) => {
		if (event.target.value === "all") {
			this._teamStore.clearSelectedTeam();
			return;
		}
		const squadId = event.target.value as number;
		const team = this._squadsStore.getSquadById(squadId);
		const isHome = this._gameweeksStore.selectedMatch?.homeSquad === team?.id;

		this._teamStore.changeSelectedTeam(team, isHome);
	};

	changeSelectedCategory = (category: MarketsCategory) => {
		this._teamStore.changeSelectedCategory(category);
	};

	@action onSelectedCategory = (event: SelectChangeEvent<unknown>) => {
		const market = event.target.value as MarketsCategory;
		this.changeSelectedCategory(market);
	};

	@action onSortByStat = (stat: string) => {
		this._currentSortBy = stat;
		this._currentOrder = this._currentOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
	};

	@action onLoadMore = () => {
		this._showLimit = this._showLimit + 5;
	};

	@action closePlayerPool = () => {
		this._teamStore.togglePlayerPool();
	};

	@action togglePlayerPoolFilter = () => {
		this._isShowPlayerPoolFilter = !this._isShowPlayerPoolFilter;
	};

	@action openTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};
}
