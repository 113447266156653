import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ICountry, ICountriesStore} from "data/stores/countries/countries.store";

interface IMyAccountFormElement extends HTMLFormElement {
	firstName: HTMLInputElement;
	surName: HTMLInputElement;
	day: HTMLInputElement;
	month: HTMLSelectElement;
	year: HTMLInputElement;
	country: HTMLInputElement;
	phone: HTMLInputElement;
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	confirmEmail: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get displayName(): string;
	get user(): IUser;
	get countries(): ICountry[];
	get isUpdateDisabled(): boolean;
	get parsedDob(): {day: number; month: string; year: number};
	get monthList(): string[];

	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _isFormChanged = false;

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get parsedDob() {
		const [days, month, year] = this.user.dob.split("/");

		return {
			day: Number(days),
			month: month,
			year: Number(year),
		};
	}

	get monthList(): string[] {
		return [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
	}

	get user() {
		return this._userStore.user!;
	}

	get displayName() {
		return this._displayName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get countries(): ICountry[] {
		return this._countriesStore.list;
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
		this._displayName = this._userStore.user!.username;
	}

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {displayName, day, month, year, phone, country} = event.currentTarget;

		const errorInvalidUsername = this.i18n.t(
			"registration.display_name.error",
			"Please provide your display name"
		);

		const errorDay = this.i18n.t("registration.password.dob", "Enter Your Date Of Birth");
		const errorPhone = this.i18n.t("registration.password.phone", "Enter Your Phone number");

		const errorCountry = this.i18n.t(
			"registration.password.country",
			"Please select Country Of Residence"
		);

		const validateList = [
			{field: displayName, error: errorInvalidUsername, place: "displayName"},
			{field: day, error: errorDay, place: "day"},
			{field: month, error: errorDay, place: "month"},
			{field: year, error: errorDay, place: "year"},
			{field: country, error: errorCountry, place: "country"},
			{field: phone, error: errorPhone, place: "phone"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const dayUpdated = parseInt(day.value, 0) < 10 ? `0${day.value}` : day.value;

		const payload = {
			displayName: displayName.value,
			dob: `${dayUpdated}/${month.value}/${year.value}`,
			country: country.value,
			phone: phone.value,
		};

		this._requestState = RequestState.PENDING;

		this._userStore
			.update(payload)
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
					this._isFormChanged = false;
				});
			})
			.catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout().then(this.onSuccess).catch(this.onError);
	};

	init() {
		void this._countriesStore.fetchCountries();
	}
}
