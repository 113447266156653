import {retryFailLoad} from "data/utils";
import React, {lazy, Fragment} from "react";
import {Route} from "react-router-dom";

const SecretLanding = lazy(
	retryFailLoad(() => import("views/pages/secret_landing/secret_landing.page"))
);
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

export const SecretGateRoutes = (
	<Fragment>
		<Route path="/" element={<SecretLanding />} />
		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default SecretGateRoutes;
