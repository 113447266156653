import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeModal.svg";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/modalStyles";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #008805;
	}
`;

const Title = styled.div`
	color: #0f0f0f;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
`;

const Description = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	max-width: 380px;
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<CheckCircleIcon />
				</Icon>
				<Title>
					{modalContent?.title
						? modalContent?.title
						: i18n.t("modal.confirm.title", "Confirm")}
				</Title>
				<Exist when={modalContent?.message}>
					<Description>{modalContent?.message}</Description>
				</Exist>
				<Stack direction="row" gap={3}>
					<Exist when={modalContent?.showCancel}>
						<ButtonPrimary fullWidth variant="contained" onClick={close}>
							{i18n.t("modal.confirm.cancel_button", "Cancel")}
						</ButtonPrimary>
					</Exist>
					<Exist when={modalContent?.callback}>
						<ButtonPrimary
							fullWidth
							variant="contained"
							onClick={modalContent?.callback}>
							{i18n.t("modal.confirm.confirm_button", "Confirm")}
						</ButtonPrimary>
					</Exist>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
