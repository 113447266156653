import React from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeModal.svg";
import {Exist} from "views/components/exist/exist.component";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/modalStyles";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #e40000;
	}
`;

const Title = styled.div`
	color: #0f0f0f;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
`;

const Description = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	width: 100%;
	max-width: 60px;
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<ErrorIcon />
				</Icon>
				<Title>{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}</Title>
				<Exist when={modalContent?.message}>
					<Description>{modalContent?.message}</Description>
				</Exist>

				<ButtonPrimaryStyled variant="contained" onClick={close}>
					{i18n.t("modal.error.button", "Close")}
				</ButtonPrimaryStyled>
			</ModalContent>
		</Modal>
	);
});
