import {injectable, inject} from "inversify";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import {IPaginationParams} from "data/types/main";
import {ILeaderboards} from "data/stores/leaderboards/leaderboards.store";

interface IWeekParams extends IPaginationParams {
	gameWeek: number;
}

interface ILeagueRankingParams {
	leagueId: number;
}

export interface ILeaderboardsProvider {
	overall: (params: IPaginationParams) => Promise<AxiosResponse<IApiResponse<ILeaderboards>>>;
	week: (params: IWeekParams) => Promise<AxiosResponse<IApiResponse<ILeaderboards>>>;
	league: (params: ILeagueRankingParams) => Promise<AxiosResponse<IApiResponse<ILeaderboards>>>;
}

@injectable()
export class LeaderboardsProvider implements ILeaderboardsProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	overall = (params: IPaginationParams) =>
		this._http.get<IApiResponse<ILeaderboards>>("daily/ranking", params);
	week = (params: IWeekParams) =>
		this._http.get<IApiResponse<ILeaderboards>>("daily/ranking/week", params);
	league = (params: ILeagueRankingParams) =>
		this._http.get<IApiResponse<ILeaderboards>>(
			`daily/ranking/league/${params.leagueId}`,
			params
		);
}
