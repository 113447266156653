import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {useNavigate} from "react-router-dom";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILandingController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isAuthorized(): boolean;
	openAuthorizationModal(): void;
}

@injectable()
export class LandingController implements ILandingController {
	@observable _navigate!: ReturnType<typeof useNavigate>;

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action init({navigate}: IProps) {
		this._navigate = navigate;
	}

	openAuthorizationModal = () => {
		if (!this.isAuthorized) {
			this._modalsStore.showModal(ModalType.AUTHORIZATION);
		} else {
			this._navigate(`my-team/`);
		}
	};
}
