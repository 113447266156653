import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const Input = styled(TextField)`
	background: transparent;
	color: #a3a3a3;
	border-radius: 4px;
	text-align: left;

	.MuiFilledInput-root,
	.MuiFilledInput-root.Mui-focused {
		//background: transparent;
		background-color: rgba(0, 0, 0, 0.09);
		border-color: #ffe000;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
		border-color: #ffe000;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		//border-color: var(--primaryColor);
		border-color: #ffe000;
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
		color: #ffe000;
		border-color: #ffe000;
	}

	.MuiFilledInput-root::before {
		border: none;
	}
	.MuiFilledInput-root::after {
		//border-color: #a3a3a3;
	}
`;

Input.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};
