import styled from "@emotion/styled";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React from "react";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as LogoImg} from "assets/img/logo.svg";
import {NavLink} from "react-router-dom";
import {useMediaQuery, Box} from "@mui/material";
import {MOBILE_VIEW_QUERY} from "data/constants";
import {Exist} from "views/components/exist/exist.component";
import {ContainerPage} from "views/components/structure/structure.component";
import {DesktopMenuItems} from "views/components/menu_items/desktop_menu_items.component";
import {MobileMenuItems} from "views/components/menu_items/mobile_menu_items.component";
import {IAuthController} from "views/controllers/auth/auth.controller";

const Wrapper = styled.div`
	background: #af0000;
	z-index: 1201;
`;

const ContainerPageStyled = styled(ContainerPage)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 64px;
`;

const RightLink = styled(NavLink)`
	color: #ffffff;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
`;

const LogoLink = styled(NavLink)`
	@media screen and (max-width: 960px) {
		margin-left: 10px;
	}
`;

export const Header: React.FC = observer(() => {
	const isMobileView = useMediaQuery(`(${MOBILE_VIEW_QUERY})`);
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {isAuthorized} = useViewController<IAuthController>(Bindings.AuthController);

	return (
		<Wrapper>
			<ContainerPageStyled>
				<Box display={"flex"} alignItems={"center"}>
					<Exist when={isMobileView}>
						<MobileMenuItems />
					</Exist>
					<LogoLink to={"/"}>
						<LogoImg />
					</LogoLink>
				</Box>

				<Box display={"flex"} alignItems={"center"}>
					<Exist when={!isMobileView && !isAuthorized}>
						<RightLink to={"/help"}>{i18n.t("manu.help", "Help")}</RightLink>
					</Exist>
					<Exist when={isAuthorized && !isMobileView}>
						<RightLink to={"/my-account"}>
							{i18n.t("manu.my_account", "My Account")}
						</RightLink>
					</Exist>
				</Box>
			</ContainerPageStyled>
			<DesktopMenuItems />
		</Wrapper>
	);
});
