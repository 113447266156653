import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeModal.svg";
import {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {ModalContent, CloseBlock} from "views/components/modals/modalStyles";
import {Exist} from "views/components/exist/exist.component";
import {PureButton, ButtonPrimary} from "views/components/buttons/buttons.component";
import logo from "assets/img/tutorial/logo.png";
import step1 from "assets/img/tutorial/tutorial1.png";
import step2 from "assets/img/tutorial/tutorial2.png";
import step3 from "assets/img/tutorial/tutorial3.png";
import step4 from "assets/img/tutorial/tutorial4.png";
import step5 from "assets/img/tutorial/tutorial5.png";
import step6 from "assets/img/tutorial/tutorial6.png";
import {range} from "lodash";
import {ReactComponent as PreviousArrow} from "assets/img/tutorial/previousArrow.svg";
import {ReactComponent as NextArrow} from "assets/img/tutorial/nextArrow.svg";
import {NavLink} from "react-router-dom";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

const StyledModalContent = styled(ModalContent)`
	gap: 13px;
	padding: 20px 15px;
`;

const Icon = styled.div`
	width: 360px;
	margin-bottom: 20px;

	img {
		width: 100%;
	}

	@media screen and (max-width: 640px) {
		width: 80%;
		margin: 0 auto 20px auto;
	}
`;

const Title = styled.h4`
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
	color: #0f0f0f;
`;

const Description = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: #0f0f0f;
	padding: 0 40px;

	@media screen and (max-width: 640px) {
		padding: 0 15px;
	}
`;

const DescriptionStartScreen = styled.p`
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
	padding: 0 40px;

	@media screen and (max-width: 640px) {
		padding: 0 15px;
	}
`;

const Buttons = styled.div`
	display: flex;
	gap: 12px;
	align-self: stretch;
	text-align: center;
	margin: 20px auto 0 auto;

	button,
	a {
		min-width: 174px;

		&.open-tutorial {
			background: #af0000 !important;
			color: #ffffff !important;
		}

		@media screen and (max-width: 640px) {
			max-width: 100%;
			width: 100%;
		}
	}

	@media screen and (max-width: 640px) {
		flex-direction: column;
		align-items: center;
	}
`;

const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	min-height: 380px;

	@media screen and (max-width: 640px) {
		min-height: 430px;
	}
`;

const ImageWrapper = styled.div`
	height: auto;
	min-height: 160px;

	img {
		max-width: 100%;
	}
`;

const IndicatorsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	margin-top: auto;
`;

const IndicatorsInner = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const Step = styled.div`
	width: 16px;
	height: 16px;
	background: #6d6d6d;
	border-radius: 50%;
`;

const NavigateButton = styled(PureButton)<{isHidden?: boolean}>`
	visibility: ${({isHidden}) => isHidden && "hidden"};

	svg {
		vertical-align: middle;
	}
`;

const StepIndicator = styled.div`
	width: calc(16px * 2 + 12px);
	height: 18px;
	background: #ffe000;
	border-radius: 5px;
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.25s;
`;

interface IProps {
	step: number;
	totalSteps: number;
	previous: () => void;
	next: () => void;
}

const Indicators: React.FC<IProps> = observer(({step, totalSteps, previous, next}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			// Step width (8px), gap (8px)
			const offsetLeft = (step - 1) * 24;
			ref.current.style.left = `${offsetLeft}px`;
		}
	}, [step]);

	return (
		<IndicatorsWrapper>
			<NavigateButton isHidden={step < 2} onClick={previous}>
				<PreviousArrow />
			</NavigateButton>
			<IndicatorsInner>
				<StepIndicator ref={ref} />
				{range(totalSteps + 1).map((stepIndex) => (
					<Step key={stepIndex} />
				))}
			</IndicatorsInner>
			<NavigateButton isHidden={step === 6} onClick={next}>
				<NextArrow />
			</NavigateButton>
		</IndicatorsWrapper>
	);
});

interface IStepProps {
	title: string;
	description: string;
	image: string;
	step: number;
	goToStartStep?: () => void;
	close?: () => void;
}

export const TutorialStep: React.FC<IStepProps> = ({
	title,
	description,
	image,
	step,
	goToStartStep,
	close,
}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<StepWrapper>
			<ImageWrapper>
				<img src={image} alt={title} />
			</ImageWrapper>
			<Title>{title}</Title>
			<Description>{description}</Description>
			<Exist when={step === 6}>
				<Buttons>
					<ButtonPrimary onClick={goToStartStep} className="open-tutorial">
						{i18n.t("tutorial.buttons.replay_tutorial", "Replay Tutorial")}
					</ButtonPrimary>
					<ButtonPrimary onClick={close} component={NavLink} to={"/my-team"}>
						{i18n.t("tutorial.buttons.get_started", "Get started")}
					</ButtonPrimary>
				</Buttons>
			</Exist>
		</StepWrapper>
	);
};

export const ModalTutorial: React.FC = observer(() => {
	const {isOpen, i18n, step, totalSteps, showIndicators, close, previous, next, goToStartStep} =
		useViewController<IModalTutorialController>(Bindings.ModalTutorialController);

	const scorePointsText = "Score Points";

	return (
		<Modal open={isOpen}>
			<StyledModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>

				<Exist when={step === 0}>
					<div>
						<Icon>
							<img src={logo} alt={"DafaNews Daily Fantasy"} />
						</Icon>
						<Title>{i18n.t("tutorial.start.header", "Welcome to")}</Title>
						<DescriptionStartScreen>
							{i18n.t("tutorial.start.copy", "DafaNews Daily Fantasy!")}
						</DescriptionStartScreen>
						<Buttons>
							<ButtonPrimary onClick={next} className="open-tutorial">
								{i18n.t("tutorial.buttons.view_tutorial", "View Tutorial")}
							</ButtonPrimary>
							<ButtonPrimary onClick={close} component={NavLink} to={"/my-team"}>
								{i18n.t("tutorial.buttons.play_now", "Play Now")}
							</ButtonPrimary>
						</Buttons>
					</div>
				</Exist>

				<Exist when={step === 1}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_title_1", "Pick Your Team")}
						description={i18n.t(
							"tutorial.steps.step_description_1",
							"For each match of the IPL season, select who you think will be the Top Batter and Bowler from each team as well as the Player of the Match."
						)}
						image={step1}
						step={step}
					/>
				</Exist>

				<Exist when={step === 2}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_description_2", scorePointsText)}
						description={i18n.t(
							"tutorial.steps.step_description_2",
							"Your selections will score points based on their odds fro that specific market. For example, if your chosen Player of the Match has odds of 4.00 then you will score 400 points if they are awarded Player of the Match!"
						)}
						image={step2}
						step={step}
					/>
				</Exist>

				<Exist when={step === 3}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_title_3", scorePointsText)}
						description={i18n.t(
							"tutorial.steps.step_description_3",
							"You will score points for your Top Batter and Bowler selections if they finish in the top 3 of players for each team in that market. If your player finishes first in that market then you will score points for 100x the odds, second will score 30x the odds and third 10x the odds."
						)}
						image={step3}
						step={step}
					/>
				</Exist>

				<Exist when={step === 4}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_title_4", "Pick Your Captain")}
						description={i18n.t(
							"tutorial.steps.step_description_4",
							"For each match you can select a captain who will score you double points!"
						)}
						image={step4}
						step={step}
					/>
				</Exist>

				<Exist when={step === 5}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_title_5", "Top the Leaderboard")}
						description={i18n.t(
							"tutorial.steps.step_description_5",
							"Compete against the other users in the global leaderboard for your chance to win weekly prizes!"
						)}
						image={step5}
						step={step}
					/>
				</Exist>

				<Exist when={step === 6}>
					<TutorialStep
						title={i18n.t("tutorial.steps.step_title_6", "You're Ready!")}
						description={i18n.t(
							"tutorial.steps.step_description_6",
							"Make your predictions now!"
						)}
						image={step6}
						step={step}
						close={close}
						goToStartStep={goToStartStep}
					/>
				</Exist>

				<Exist when={showIndicators}>
					<Indicators
						previous={previous}
						next={next}
						step={step}
						totalSteps={totalSteps}
					/>
				</Exist>
			</StyledModalContent>
		</Modal>
	);
});
