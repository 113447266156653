import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import {MS_IN_SECOND, SEC_IN_DAY, SEC_IN_HOUR, HRS_IN_DAY, MIN_IN_HOUR} from "data/utils/countdown";

export interface IModalTeamSavedController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get timeToStartMatch(): string;

	close: () => void;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED_CONFIRMATION;
	}

	get timeToStartMatch() {
		const msToFinish =
			Number(new Date(this._gameweeksStore.selectedMatch?.startAt || "")) - Date.now();

		if (msToFinish <= 0) {
			return "00";
		}

		const secToFinish = Math.floor(msToFinish / MS_IN_SECOND);

		const days = Math.floor(secToFinish / SEC_IN_DAY);
		const hours = Math.floor(secToFinish / SEC_IN_HOUR) % HRS_IN_DAY;
		const minutes = Math.floor(secToFinish / MIN_IN_HOUR) % MIN_IN_HOUR;
		// const seconds = Math.floor(secToFinish % SEC_IN_MINUTE);
		return `${days} days ${hours} hours ${minutes} minute`;
	}

	close = () => {
		this._modalsStore.hideModal();
	};
}
