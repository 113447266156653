import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ITeamApiProvider} from "data/providers/api/team.api.provider";
import {MarketsCategory, PlayerMarketsCategories} from "data/enums";
import {findKey} from "lodash";
import type {ISquad} from "data/stores/squads/squads.store";

export interface ITeam {
	id?: number;
	match: number | null;
	lineup: number[];
	captain: number | null;
	name: string;
}

export interface ITeamStore {
	get getIsLoading(): boolean;
	get selectedTeam(): ISquad | undefined;
	get selectedCategory(): MarketsCategory;
	get selectedMarketCategoryInTeam(): PlayerMarketsCategories;
	get isCurrentMarkedSelected(): boolean;
	get isTeamSaveDisabled(): boolean;
	get lineupIds(): number[];
	get captainId(): number | null;
	get lineupByMarkets(): {[key: string]: number};
	get lineup(): number[];
	get isOpenPlayerPool(): boolean;
	get isTeamChanged(): boolean;
	get playersMarketCategory(): PlayerMarketsCategories;
	get isTeamHome(): boolean;
	get isLineupEmpty(): boolean;

	fetchTeamByMatchId(matchId: number): Promise<void>;
	changeSelectedTeam(team: ISquad | undefined, isHome: boolean): void;
	clearSelectedTeam(): void;
	changeSelectedCategory(category: MarketsCategory): void;
	setPlayersFilter(marketCategory: PlayerMarketsCategories): void;
	saveTeam(matchId: number, userDisplayName: string): Promise<void>;
	setCaptain(lineupPlayerId: number): void;
	removeFromLineup(lineupPlayerId: number): void;
	togglePlayerPool(): void;

	addPlayerToLineup: (playerId: number, marketCategory?: PlayerMarketsCategories) => void;
	resetTeam: () => void;
	getPlayerLineupIdByMarket: (marketCategory: PlayerMarketsCategories) => number;
}

@injectable()
export class TeamStore implements ITeamStore {
	@observable private _isLoading: boolean = false;
	@observable private _teamChanged: boolean = false;
	@observable private _isOpenPlayerPool: boolean = false;
	@observable private _isTeamHome: boolean = true;

	private _lineupByMarkets = {
		[PlayerMarketsCategories.TeamHomeTopBatter]: 0,
		[PlayerMarketsCategories.TeamHomeTopBowler]: 0,
		[PlayerMarketsCategories.TeamAwayTopBatter]: 0,
		[PlayerMarketsCategories.TeamAwayTopBowler]: 0,
		[PlayerMarketsCategories.PlayerOfTheMatch]: 0,
	};

	@observable private _team: ITeam = {
		id: 0,
		match: null,
		lineup: [],
		captain: null,
		name: "",
	};
	@observable private _playerMarketsCategories: PlayerMarketsCategories =
		PlayerMarketsCategories.PlayerOfTheMatch;
	@observable private _selectedCategory: MarketsCategory = MarketsCategory.General;
	@observable private _selectedTeam: ISquad | undefined;

	constructor(@inject(Bindings.TeamApiProvider) private _teamProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get selectedTeam(): ISquad | undefined {
		return this._selectedTeam;
	}

	get selectedCategory(): MarketsCategory {
		return this._selectedCategory;
	}

	get playersMarketCategory(): PlayerMarketsCategories {
		return this._playerMarketsCategories;
	}

	get selectedMarketCategoryInTeam(): PlayerMarketsCategories {
		return this.playersMarketCategory;
	}

	get isCurrentMarkedSelected() {
		return !this._lineupByMarkets[this.playersMarketCategory];
	}

	get isTeamSaveDisabled(): boolean {
		return (
			this.lineupIds.find((item) => item === 0) === 0 ||
			!this._team.captain ||
			!this._teamChanged
		);
	}

	get lineupIds() {
		return [
			this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBatter],
			this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBatter],
			this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBowler],
			this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBowler],
			this._lineupByMarkets[PlayerMarketsCategories.PlayerOfTheMatch],
		];
	}

	get lineup() {
		return this._team.lineup;
	}

	get lineupByMarkets() {
		return this._lineupByMarkets;
	}

	get captainId() {
		return this._team.captain;
	}

	get isOpenPlayerPool() {
		return this._isOpenPlayerPool;
	}

	get isTeamChanged() {
		return this._teamChanged;
	}

	get isTeamHome() {
		return this._isTeamHome;
	}

	get isLineupEmpty() {
		return this._lineupByMarkets[PlayerMarketsCategories.PlayerOfTheMatch] === 0;
	}

	private changeSelectedCategoryByMarketCategory(marketCategory: PlayerMarketsCategories) {
		if (
			marketCategory === PlayerMarketsCategories.TeamHomeTopBatter ||
			marketCategory === PlayerMarketsCategories.TeamAwayTopBatter
		) {
			this._selectedCategory = MarketsCategory.Batting;
		} else if (
			marketCategory === PlayerMarketsCategories.TeamAwayTopBowler ||
			marketCategory === PlayerMarketsCategories.TeamHomeTopBowler
		) {
			this._selectedCategory = MarketsCategory.Bowling;
		} else {
			this._selectedCategory = MarketsCategory.General;
		}
	}

	@action
	changeSelectedTeam(team: ISquad | undefined, isTeamHome: boolean) {
		this._isTeamHome = isTeamHome;
		this._selectedTeam = team;

		if (this._selectedCategory === MarketsCategory.Batting) {
			this._playerMarketsCategories = isTeamHome
				? PlayerMarketsCategories.TeamHomeTopBatter
				: PlayerMarketsCategories.TeamAwayTopBatter;
		} else if (this._selectedCategory === MarketsCategory.Bowling) {
			this._playerMarketsCategories = isTeamHome
				? PlayerMarketsCategories.TeamHomeTopBowler
				: PlayerMarketsCategories.TeamAwayTopBowler;
		} else {
			this._playerMarketsCategories = PlayerMarketsCategories.PlayerOfTheMatch;
		}
	}

	@action
	clearSelectedTeam() {
		this._selectedTeam = undefined;
	}

	@action
	changeSelectedCategory(category: MarketsCategory) {
		this._selectedCategory = category;
	}
	@action
	setPlayersFilter(marketCategory: PlayerMarketsCategories) {
		this._playerMarketsCategories = marketCategory;

		if (marketCategory === PlayerMarketsCategories.PlayerOfTheMatch) {
			this.changeSelectedCategory(MarketsCategory.General);
		}

		if (
			marketCategory === PlayerMarketsCategories.TeamHomeTopBatter ||
			marketCategory === PlayerMarketsCategories.TeamAwayTopBatter
		) {
			this.changeSelectedCategory(MarketsCategory.Batting);
			this._isTeamHome = marketCategory === PlayerMarketsCategories.TeamHomeTopBatter;
		}

		if (
			marketCategory === PlayerMarketsCategories.TeamHomeTopBowler ||
			marketCategory === PlayerMarketsCategories.TeamAwayTopBowler
		) {
			this.changeSelectedCategory(MarketsCategory.Bowling);
			this._isTeamHome = marketCategory === PlayerMarketsCategories.TeamHomeTopBowler;
		}
	}

	@action
	addPlayerToLineup(lineupPlayerId: number, marketCategory?: PlayerMarketsCategories) {
		if (marketCategory) {
			this._lineupByMarkets[marketCategory] = lineupPlayerId;
		} else {
			this._lineupByMarkets[this.playersMarketCategory] = lineupPlayerId;
			this._teamChanged = true;
		}

		if (!this._team.captain) {
			this.setCaptain(lineupPlayerId);
		}

		const orderedLineup = {
			[PlayerMarketsCategories.TeamHomeTopBatter]:
				this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBatter],
			[PlayerMarketsCategories.TeamHomeTopBowler]:
				this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBowler],
			[PlayerMarketsCategories.TeamAwayTopBatter]:
				this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBatter],
			[PlayerMarketsCategories.TeamAwayTopBowler]:
				this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBowler],
			[PlayerMarketsCategories.PlayerOfTheMatch]:
				this._lineupByMarkets[PlayerMarketsCategories.PlayerOfTheMatch],
		};

		const nextCategory = findKey(orderedLineup, (playerId) => playerId === 0) as
			| PlayerMarketsCategories
			| undefined;

		if (nextCategory && !marketCategory) {
			this._isTeamHome =
				nextCategory === PlayerMarketsCategories.TeamHomeTopBowler ||
				nextCategory === PlayerMarketsCategories.TeamHomeTopBatter;

			this.changeSelectedCategoryByMarketCategory(nextCategory);
			this._playerMarketsCategories = nextCategory;
		}
	}

	@action
	async fetchTeamByMatchId(matchId: number) {
		const {data} = await this._teamProvider.getTeam({
			matchId,
		});

		runInAction(() => {
			this._team = data.success.team;

			this._lineupByMarkets = {
				[PlayerMarketsCategories.PlayerOfTheMatch]: 0,
				[PlayerMarketsCategories.TeamHomeTopBatter]: 0,
				[PlayerMarketsCategories.TeamAwayTopBatter]: 0,
				[PlayerMarketsCategories.TeamHomeTopBowler]: 0,
				[PlayerMarketsCategories.TeamAwayTopBowler]: 0,
			};
		});
	}

	@action
	setCaptain(lineupPlayerId: number) {
		this._team.captain = lineupPlayerId;
		this._teamChanged = true;
	}

	@action
	removeFromLineup(lineupPlayerId: number) {
		const marketName = findKey(
			this._lineupByMarkets,
			(lineupId) => lineupId === lineupPlayerId
		) as PlayerMarketsCategories | undefined;

		if (marketName) {
			this._lineupByMarkets[marketName] = 0;

			if (this._team.captain === lineupPlayerId) {
				this._team.captain = 0;
			}
			this._teamChanged = true;
		}
	}

	@action
	async saveTeam(matchId: number, userDisplayName: string) {
		this._team.lineup = [
			this._lineupByMarkets[PlayerMarketsCategories.PlayerOfTheMatch],
			this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBatter],
			this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBatter],
			this._lineupByMarkets[PlayerMarketsCategories.TeamHomeTopBowler],
			this._lineupByMarkets[PlayerMarketsCategories.TeamAwayTopBowler],
		];

		const {data} = await this._teamProvider.teamSave({
			// "name" in create team is crutch for BE.
			// BE dev couldn't update team on his side so we add user display name every time on create and update team.
			name: userDisplayName,
			match: matchId,
			lineup: this.lineupIds,
			captain: this._team.captain,
		});

		runInAction(() => {
			this._team = data.success.team;
			this._teamChanged = false;
		});
	}

	@action
	resetTeam() {
		runInAction(() => {
			this._lineupByMarkets = {
				[PlayerMarketsCategories.PlayerOfTheMatch]: 0,
				[PlayerMarketsCategories.TeamHomeTopBatter]: 0,
				[PlayerMarketsCategories.TeamAwayTopBatter]: 0,
				[PlayerMarketsCategories.TeamHomeTopBowler]: 0,
				[PlayerMarketsCategories.TeamAwayTopBowler]: 0,
			};
			this._team.captain = 0;
		});
	}

	@action
	togglePlayerPool() {
		runInAction(() => {
			this._isOpenPlayerPool = !this._isOpenPlayerPool;
		});
	}

	getPlayerLineupIdByMarket(marketCategory: PlayerMarketsCategories) {
		return this._lineupByMarkets[marketCategory];
	}
}
