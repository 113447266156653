import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {MatchStatus, ModalType, PlayerMarketsCategories, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IGameweek, IGameweeksStore, IMatch} from "data/stores/gameweeks/gameweeks.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {get, last} from "lodash";
import type {SelectChangeEvent} from "@mui/material";
import {GameBarStore, IGameBar} from "data/stores/game_bar/game_bar.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";

export interface IDashboardController extends ViewController {
	readonly i18n: ILocalizationStore;
	get teamName(): string;
	get selectedGameweek(): IGameweek | undefined;
	get selectedMatch(): IMatch | undefined;
	get disabledPrevSelect(): boolean;
	get disabledNextSelect(): boolean;
	get gameBar(): IGameBar;
	get user(): IUser;

	setSelectedMatch(matchId: number): void;
	getMatchStatus(status: MatchStatus, isMarketsReady: boolean): string;
	setPrevGameweek(): void;
	setNextGameweek(): void;
	onChangeSelectedMatch(event: SelectChangeEvent<unknown>): void;
	getMatchTitle: (match: IMatch) => string;
}

@injectable()
export class DashboardController implements IDashboardController {
	@observable _requestState = RequestState.PENDING;

	get teamName() {
		return this.gameBar.teamName || "";
	}

	get selectedGameweek() {
		return this._gameweeksStore.selectedGameweek;
	}

	get selectedMatch() {
		return this._gameweeksStore.selectedMatch;
	}

	get disabledPrevSelect() {
		return this.selectedGameweek?.id === 1;
	}

	get disabledNextSelect() {
		return this.selectedGameweek?.id === last(this._gameweeksStore.list)?.id;
	}

	get gameBar() {
		return this._gameBarStore.gameBar;
	}

	get user() {
		return this._userStore.user!;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: GameBarStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action private updateTeamLineup = () => {
		const lineupByMarkets = this._teamStore.lineupByMarkets;
		this._teamStore.lineup.forEach((lineupId) => {
			const player = this._playersStore.getPlayerByLineupId(lineupId);
			if (player?.marketName && get(lineupByMarkets, player.marketName, null) !== null) {
				this._teamStore.addPlayerToLineup(lineupId, player.marketName);
			}
		});
		this._teamStore.setPlayersFilter(PlayerMarketsCategories.PlayerOfTheMatch);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action setSelectedMatch = (matchId: number) => {
		this._gameweeksStore.setSelectedMatch(matchId);
		void Promise.all([
			this._playersStore.fetchMatchMarketPlayers(matchId),
			this._teamStore.fetchTeamByMatchId(matchId),
			this._gameBarStore.fetchGameBar(
				this._gameweeksStore.selectedGameweek?.id || 0,
				matchId
			),
		])
			.then(() => {
				// const squads = this._squadsStore.getSquadsByMatch(this.selectedMatch);
				// const selectedSquad = first(squads);
				// const isHome = this._gameweeksStore.selectedMatch?.homeSquad === selectedSquad?.id;
				this._teamStore.clearSelectedTeam();
			})
			.catch(() => {
				// Don't show errors
			})
			.finally(this.updateTeamLineup);
	};

	private setSelectedGameweek = (gameweekId: number) => {
		this._gameweeksStore.setSelectedGameweek(gameweekId);
		this.setSelectedMatch(this.selectedMatch?.id || 0);
	};

	@action setPrevGameweek = () => {
		const newGameweekId = get(this.selectedGameweek, "id", 1) - 1;
		this.setSelectedGameweek(newGameweekId);
	};

	@action setNextGameweek = () => {
		const newGameweekId = get(this.selectedGameweek, "id", 1) + 1;
		this.setSelectedGameweek(newGameweekId);
	};

	getMatchStatus = (status: MatchStatus, isMarketsReady: boolean) => {
		if (!isMarketsReady) {
			return this.i18n.t("dashboard.match.statuses.open_soon", "Open Soon");
		}

		if (status === MatchStatus.Playing) {
			return this.i18n.t("dashboard.match.statuses.in_progress", "In Progress");
		}

		if (status === MatchStatus.Complete) {
			return this.i18n.t("dashboard.match.statuses.complete", "Complete");
		}

		return this.i18n.t("dashboard.match.statuses.pick_team", "Pick Team");
	};

	onChangeSelectedMatch = (event: SelectChangeEvent<number>) => {
		const matchId = event.target.value as number;
		this.setSelectedMatch(matchId);
	};

	getMatchTitle = (match: IMatch) => {
		const homeSquad = this._squadsStore.getSquadById(match.homeSquad);
		const awaySquad = this._squadsStore.getSquadById(match.awaySquad);

		return `${homeSquad?.abbreviation || ""} vs ${awaySquad?.abbreviation || ""}`;
	};
}
