import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, toJS} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";
import type {ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import {useNavigate} from "react-router-dom";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface IRegistrationFormElement extends HTMLFormElement {
	firstName: HTMLInputElement;
	surName: HTMLInputElement;
	day: HTMLInputElement;
	month: HTMLSelectElement;
	year: HTMLInputElement;
	country: HTMLInputElement;
	phone: HTMLInputElement;
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	confirmEmail: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	marketingOptIn: HTMLInputElement;
	terms: HTMLInputElement;
	marketing: HTMLInputElement;
}

export interface IRegistrationFormController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get countries(): ICountry[];
	get activeStep(): number;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class RegistrationFormController implements IRegistrationFormController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable _navigate!: ReturnType<typeof useNavigate>;
	@observable private _activeStep = 1;

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get countries(): ICountry[] {
		return this._countriesStore.list;
	}

	get activeStep() {
		return this._activeStep;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action init({navigate}: IProps) {
		void this._countriesStore.fetchCountries();
		this._navigate = navigate;
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();
		let validateList;

		const {
			firstName,
			surName,
			displayName,
			email,
			confirmEmail,
			password,
			confirmPassword,
			terms,
			day,
			month,
			year,
			phone,
			country,
			marketing,
		} = event.currentTarget;

		const errorInvalidFirstName = this.i18n.t(
			"registration.first_name.error",
			"Please provide your first name"
		);
		const errorInvalidSurName = this.i18n.t(
			"registration.last_name.error",
			"Please provide your last name"
		);
		const errorInvalidUsername = this.i18n.t(
			"registration.display_name.error",
			"Please provide your display name"
		);
		const errorInvalidEmail = this.i18n.t(
			"registration.email.error",
			"Please provide a valid email address"
		);
		const errorInvalidConfirmEmail = this.i18n.t(
			"registration.confirm_email.error",
			"Confirm email address is invalid"
		);
		const errorInvalidPassword = this.i18n.t(
			"registration.password.error",
			PASSWORD_REQUIREMENTS
		);
		const errorInvalidTerms = this.i18n.t(
			"registration.terms.error",
			"Please accept Terms & Conditions"
		);
		const errorEmailsMismatch = this.i18n.t(
			"registration.email.mismatch_error",
			"Emails do not match"
		);
		const errorPasswordsMismatch = this.i18n.t(
			"registration.password.mismatch_error",
			"Passwords do not match"
		);
		const errorDay = this.i18n.t("registration.password.dob", "Enter Your Date Of Birth");
		const errorPhone = this.i18n.t("registration.password.phone", "Enter Your Phone number");

		const errorCountry = this.i18n.t(
			"registration.password.country",
			"Please select Country Of Residence"
		);

		if (this._activeStep === 1) {
			validateList = [
				{field: firstName, error: errorInvalidFirstName, place: "firstName"},
				{field: surName, error: errorInvalidSurName, place: "surName"},
				{field: email, error: errorInvalidEmail, place: "email"},
				{field: confirmEmail, error: errorInvalidConfirmEmail, place: "confirmEmail"},
				{field: password, error: errorInvalidPassword, place: "password"},
			];
		} else {
			validateList = [
				{field: displayName, error: errorInvalidUsername, place: "displayName"},
				{field: day, error: errorDay, place: "day"},
				{field: month, error: errorDay, place: "month"},
				{field: year, error: errorDay, place: "year"},
				{field: country, error: errorCountry, place: "country"},
				{field: phone, error: errorPhone, place: "phone"},
				{field: terms, error: errorInvalidTerms, place: "terms"},
			];
		}

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (email.value !== confirmEmail.value) {
			return this.reportError(errorEmailsMismatch, "confirmEmail");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError(errorPasswordsMismatch, "confirmPassword");
		}

		if (this._activeStep === 1) {
			this._activeStep = 2;
		} else {
			const dayUpdated = parseInt(day.value, 0) < 10 ? `0${parseInt(day.value)}` : day.value;

			const payload = {
				firstName: firstName.value,
				surName: surName.value,
				displayName: displayName.value,
				email: email.value,
				password: password.value,
				dob: `${dayUpdated}/${month.value}/${year.value}`,
				marketingOptIn: Boolean(marketing),
				country: country.value,
				phone: phone.value,
			};

			this._requestState = RequestState.PENDING;
			this._userStore
				.register(payload)
				.then(() => {
					this._modalsStore.hideModal();
					this._navigate("my-team/");
				})
				.catch(this.onError);
		}
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		console.log(toJS(error));
		this._errorMsg = extractErrorMessage(error);
		this._errorPlace = error.response?.data?.errors?.[0]?.field || "common";

		const step1Fields = ["firstName", "surName", "password", "email"];

		if (step1Fields.includes(this._errorPlace)) {
			this._activeStep = 1;
		}

		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}
	};

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			const errorInvalidPassword = this.i18n.t(
				"registration.password.error",
				PASSWORD_REQUIREMENTS
			);
			this.reportError(errorInvalidPassword, "password");
		}
	};
}
