import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {MatchStatus, PlayerMarketsCategories} from "data/enums";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";

interface IParams {
	market: PlayerMarketsCategories;
}

export interface ITeamPlayerController extends ViewController<IParams> {
	readonly i18n: ILocalizationStore;
	get isPositionSelected(): boolean;
	get player(): IPlayer | undefined;
	get playerSquad(): ISquad | undefined;
	get isPlayerCaptain(): boolean;
	get isLockout(): boolean;
	get isPlayingGame(): boolean;
	get isCompleteGame(): boolean;
	get isLoading(): boolean;
	get playerPoints(): number;
	get isWinner(): boolean;

	onSetPlayersFilter: () => void;
	onSetCaptain: () => void;
	onRemovePlayer: () => void;
}

@injectable()
export class TeamPlayerController implements ITeamPlayerController {
	@observable private _marketCategory: PlayerMarketsCategories =
		PlayerMarketsCategories.PlayerOfTheMatch;

	get player() {
		const lineupPlayerId = this._teamStore.getPlayerLineupIdByMarket(this._marketCategory);
		return lineupPlayerId
			? this._playersStore.getPlayerByLineupIdAndMarket(lineupPlayerId, this._marketCategory)
			: undefined;
	}

	get playerSquad() {
		return this._squadsStore.getSquadById(this.player?.squadId || 0);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.GameweeksStore) private _gameweeksStore: IGameweeksStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get isPositionSelected() {
		return this._teamStore.selectedMarketCategoryInTeam === this._marketCategory;
	}

	get isPlayerCaptain() {
		return this.player?.lineupPlayerId === this._teamStore.captainId;
	}

	get isLockout() {
		return this._gameweeksStore.selectedMatch?.status !== MatchStatus.Scheduled;
	}

	get isPlayingGame() {
		return this._gameweeksStore.selectedMatch?.status === MatchStatus.Playing;
	}

	get isCompleteGame() {
		return this._gameweeksStore.selectedMatch?.status === MatchStatus.Complete;
	}

	get isLoading(): boolean {
		return this._playersStore.getIsLoading;
	}

	get playerPoints(): number {
		if (!this.player?.scores) {
			return 0;
		}

		const scores = this.isFloat(this.player.scores)
			? Math.round(this.player.scores * 10) / 10
			: this.player.scores;

		return this.isPlayerCaptain ? scores * 2 : scores;
	}

	get isWinner(): boolean {
		return this.playerPoints !== 0;
	}

	init({market}: IParams) {
		this._marketCategory = market;
	}

	private isFloat(n: number) {
		return Number(n) === n && n % 1 !== 0;
	}
	@action
	onSetPlayersFilter = () => {
		this._teamStore.setPlayersFilter(this._marketCategory);
		this._teamStore.togglePlayerPool();
		const element = document.getElementById("playerPool");
		element?.scrollIntoView();
	};

	@action
	onSetCaptain = () => {
		this._teamStore.setCaptain(this.player?.lineupPlayerId || 0);
	};

	@action
	onRemovePlayer = () => {
		this._teamStore.removeFromLineup(this.player?.lineupPlayerId || 0);
	};
}
