import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IResetPasswordController extends ViewController {
	i18n: ILocalizationStore;
}

@injectable()
export class ResetPasswordController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	init() {
		this._modalsStore.showModal(ModalType.RESET_PASSWORD);
	}
}
