export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	CONFIRM_ALERT,
	AUTHORIZATION,
	RESET_PASSWORD,
	TEAM_SAVED_CONFIRMATION,
	TUTORIAL,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum GameweeksStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	None = "none",
	General = "general",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum MarketsCategory {
	General = "General",
	Batting = "Batting",
	Bowling = "Bowling",
}

export enum PlayerMarketsCategories {
	PlayerOfTheMatch = "Player of the Match",
	TeamHomeTopBatter = "Team Home Top Batter",
	TeamAwayTopBatter = "Team Away Top Batter",
	TeamHomeTopBowler = "Team Home Top Bowler",
	TeamAwayTopBowler = "Team Away Top Bowler",
}

export enum AuthorizationSection {
	Login = "login",
	Registration = "registration",
	ForgotPassword = "Forgot Password",
}
