import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ChangeEvent} from "react";
import {PASSWORD_REQUIREMENTS} from "data/constants";
import {RequestState} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";

export interface IChangePasswordController extends ViewController {
	i18n: ILocalizationStore;
	get isDisabled(): boolean;
	get error(): string | null;
	get confirmError(): string | null;
	get showPass(): boolean;
	set showPass(value: boolean);

	get requestState(): RequestState;
	get showConfirmPass(): boolean;
	set showConfirmPass(value: boolean);
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;
	validateConfirmPass: (value: string) => void;
	onChangeConfirmPass: (event: ChangeEvent<HTMLInputElement>) => void;

	update: () => void;
}

@injectable()
export class ChangePasswordController implements IChangePasswordController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _showPass = false;
	@observable _showConfirmPass = false;
	@observable private _errorMsg: string | null = null;
	@observable private _confirmError: string | null = null;
	@observable private _password = "";
	@observable private _confirmPass = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isDisabled() {
		return Boolean(
			!this._password.length ||
				this._confirmError ||
				this._errorMsg ||
				this._password !== this._confirmPass
		);
	}

	get requestState() {
		return this._requestState;
	}

	get error() {
		return this._errorMsg;
	}

	get confirmError() {
		return this._confirmError;
	}

	get showPass() {
		return this._showPass;
	}

	set showPass(value: boolean) {
		this._showPass = value;
	}

	get showConfirmPass() {
		return this._showConfirmPass;
	}

	set showConfirmPass(value: boolean) {
		this._showConfirmPass = value;
	}

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
			this._password = event.currentTarget.value;
		} else {
			this._errorMsg = this.i18n.t("registration.password.error", PASSWORD_REQUIREMENTS);
		}
	};

	@action onChangeConfirmPass = (event: ChangeEvent<HTMLInputElement>) => {
		this._confirmPass = event.currentTarget.value;
	};

	@action validateConfirmPass = (value: string) => {
		if (value !== this._password) {
			this._confirmError = this.i18n.t(
				"registration.password.mismatch_error",
				"Passwords do not match"
			);
		} else {
			this._confirmError = null;
		}
	};

	update = () => {
		this._requestState = RequestState.PENDING;
		this._userStore
			.update({
				password: this._password,
			})
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch((e) => {
				this._requestState = RequestState.ERROR;
				this._errorMsg = extractErrorMessage(e as AxiosError<IApiResponse>);
			});
	};
}
