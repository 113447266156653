import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";

export interface IGameBar {
	userId: number | null;
	userName: string | null;
	teamName: string | null;
	teamId: number | null;
	matchPoints: number | null;
	gameWeekPoints: number | null;
	overallPoints: number | null;
}

export interface IGameBarStore {
	fetchGameBar(gameWeekID: number, matchId: number): Promise<void>;
	gameBar: IGameBar;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBar: IGameBar = {
		userId: null,
		userName: null,
		teamName: null,
		teamId: null,
		matchPoints: null,
		gameWeekPoints: null,
		overallPoints: null,
	};

	get gameBar() {
		return this._gameBar;
	}

	constructor(@inject(Bindings.TeamApiProvider) private _teamProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	@action async fetchGameBar(gameWeekID: number, matchId: number) {
		const {data} = await this._teamProvider.gameBar(gameWeekID, matchId);

		runInAction(() => {
			this._gameBar = data.success;
		});
	}
}
