import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {ChangeEvent, SyntheticEvent} from "react";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {ModalType} from "data/enums";

interface IProps {
	isJoinPage: boolean;
}
interface ISearchForm extends HTMLFormElement {
	search: HTMLInputElement;
}

export interface IJoinCreateLeagueCardController extends ViewController<IProps> {
	handleChange: (e: ChangeEvent<HTMLFormElement>) => void;

	handleSubmit: (e: SyntheticEvent<ISearchForm>) => void;
	readonly i18n: ILocalizationStore;
}

@injectable()
export class JoinCreateLeagueCardController implements IJoinCreateLeagueCardController {
	@observable _isJoinPage: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action handleSubmit = (e: SyntheticEvent<ISearchForm>) => {
		e.preventDefault();
		const {search} = e.currentTarget;

		if (search.value.length) {
			this.searchLeagues(search.value);
		} else {
			this.clearAction();
		}
	};

	@action handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const value = e.target.value as string;

		console.log(value);

		if (value.length) {
			this.searchLeagues(value);
		} else {
			this.clearAction();
		}
	};

	private clearAction = () => {
		if (this._isJoinPage) {
			this._leaguesStore.clearLeagueForJoinSearch();
		} else {
			this._leaguesStore.clearMyLeaguesSearch();
		}
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private searchLeagues = (search: string) => {
		if (this._isJoinPage) {
			void this._leaguesStore.fetchLeaguesForJoin({search}).catch(this.onError);
		} else {
			void this._leaguesStore.fetchMyLeagues({search}).catch(this.onError);
		}
	};

	dispose() {
		return;
	}

	init({isJoinPage}: IProps) {
		this._isJoinPage = isJoinPage;
	}
}
