import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {type ITeam} from "data/stores/team/team.store";
import {IGameBar} from "data/stores/game_bar/game_bar.store";

export interface ITeamPayload {
	matchId: number;
	userId?: number;
}

export type TTeamResponse = IApiResponse<{team: ITeam}>;
export type TGameBarResponse = IApiResponse<IGameBar>;

export interface ITeamApiProvider {
	getTeam: (params: ITeamPayload) => Promise<AxiosResponse<TTeamResponse>>;
	teamSave: (params: ITeam) => Promise<AxiosResponse<TTeamResponse>>;
	gameBar: (gameWeekID: number, matchId: number) => Promise<AxiosResponse<TGameBarResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getTeam = ({matchId}: ITeamPayload) => this._http.get<TTeamResponse>(`daily/team/${matchId}`);

	teamSave = (params: ITeam) => this._http.post<TTeamResponse>("daily/team", params);

	gameBar = (gameWeekID: number, matchId: number) =>
		this._http.get<TGameBarResponse>(`daily/game-bar/${gameWeekID}/${matchId}`);
}
