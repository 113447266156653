import React, {lazy, Fragment} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {MainLayout} from "views/components/layouts/main_layout.component";

const Home = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const MyTeam = lazy(retryFailLoad(() => import("views/pages/my_team/my_team.page")));
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueManage = lazy(
	retryFailLoad(() => import("views/pages/league_manage/league_manage.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const LeaderboardPage = lazy(
	retryFailLoad(() => import("views/pages/leaderboards/leaderboards.page"))
);

export const RootRoutes = (
	<Fragment>
		<Route element={<MainLayout />}>
			<Route index element={<Home />} />
			<Route element={<NotAuthOnlyRoute />}>
				<Route path="/reset-password" element={<ResetPassword />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path="/my-team" element={<MyTeam />} />
				<Route path="/my-account" element={<MyAccount />} />
				<Route path="/leagues" element={<MyLeagues />} />
				<Route path="/leagues/join" element={<JoinLeagues />}>
					<Route index path=":code" element={<JoinLeagues />} />
				</Route>
				<Route path="/leagues/create" element={<CreateLeague />} />
				<Route path="/league/:leagueId" element={<League />}>
					<Route path="invite" element={<LeagueInvite />} />
					<Route path="settings" element={<LeagueSettings />} />
					<Route path="manage" element={<LeagueManage />} />
					<Route path="about" element={<LeagueAbout />} />
					<Route path="leaderboard" element={<LeagueTable />} />
				</Route>
				<Route path="/leaderboard" element={<LeaderboardPage />} />
			</Route>
			<Route path="components" element={<ComponentsReview />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Fragment>
);

export default RootRoutes;
