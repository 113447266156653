import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {
	type ILoginFormController,
	LoginFormController,
} from "views/components/login_form/login_form.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {GameweeksStore, type IGameweeksStore} from "data/stores/gameweeks/gameweeks.store";
import {MatchesStore, type IMatchesStore} from "data/stores/matches/matches.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {
	type IRegistrationFormController,
	RegistrationFormController,
} from "views/components/registration_form/registration_form.controller";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordFormController,
	type IForgotPasswordFormController,
} from "views/components/forgot_password_form/forgot_password_form.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {type ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {type ITeamStore, TeamStore} from "data/stores/team/team.store";
import {
	type IMenuItemsController,
	MenuItemsController,
} from "views/components/menu_items/menu_items.controller";
import {
	IComponentsReviewController,
	ComponentsReviewController,
} from "views/pages/components_review/components_review.controller";
import {type IMyTeamController, MyTeamController} from "views/pages/my_team/my_team.controller";
import {
	type IPlayersPoolController,
	PlayersPoolController,
} from "views/components/players_pool/players_pool.controller";
import {
	type IPlayerCardController,
	PlayerCardController,
} from "views/components/players_pool/player_pool_card/player_pool_card.controller";
import {
	type ITeamLineupController,
	TeamLineupController,
} from "views/components/team_lineup/team_lineup.controller";
import {
	type ITeamPlayerController,
	TeamPlayerController,
} from "views/components/team_lineup/team_player/team_player.controller";
import {
	type IJoinCreateLeagueCardController,
	JoinCreateLeagueCardController,
} from "views/components/join_create_league_card/join_create_league_card.controller";
import {
	type ITeamBarController,
	TeamBarController,
} from "views/components/team_bar/team_bar.controller";
import {
	type IDashboardController,
	DashboardController,
} from "views/components/dashboard/dashboard.controller";
import {
	type IModalAuthorizationController,
	ModalAuthorizationController,
} from "views/components/modals/modal_authorization/modal_authorization.controller";
import {
	type IInvitedLeaguesController,
	InvitedLeaguesController,
} from "views/components/invited_leagues/invited_leagues.controller";
import {
	type IModalResetPasswordController,
	ModalResetPasswordController,
} from "views/components/modals/modal_reset_password/modal_reset_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ILeagueManageController,
	LeagueManageController,
} from "views/pages/league_manage/league_manage.controller";
import {
	type IModalTeamSavedController,
	ModalTeamSavedController,
} from "views/components/modals/modal_team_saved/modal_team_saved.controller";
import {type IGameBarStore, GameBarStore} from "data/stores/game_bar/game_bar.store";
import {
	ModalConfirmAlertController,
	IModalConfirmAlertController,
} from "views/components/modals/modal_confirm_alert/modal_confirm_alert.controller";
import {
	type IChangePasswordController,
	ChangePasswordController,
} from "views/components/change_password/change_password.controller";
import {
	type IUpdateNotificationController,
	UpdateNotificationController,
} from "views/components/update_notification/update_notification.controller";
import {
	type IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {
	type ILeaderboardsController,
	LeaderboardsController,
} from "views/pages/leaderboards/leaderboards.controller";
import {
	type ILeaderboardsProvider,
	LeaderboardsProvider,
} from "data/providers/leaderboards/leaderboards.provider";
import {
	type ILeaderboardsStore,
	LeaderboardsStore,
} from "data/stores/leaderboards/leaderboards.store";
import {
	type ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<ILeaderboardsProvider>(Bindings.LeaderboardsProvider).to(LeaderboardsProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IMatchesStore>(Bindings.MatchesStore).to(MatchesStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<IGameweeksStore>(Bindings.GameweeksStore).to(GameweeksStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<ILeaderboardsStore>(Bindings.LeaderboardsStore).to(LeaderboardsStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginFormController>(Bindings.LoginFormController).to(LoginFormController);
	bind<IForgotPasswordFormController>(Bindings.ForgotPasswordFormController).to(
		ForgotPasswordFormController
	);
	bind<IModalResetPasswordController>(Bindings.ModalResetPasswordController).to(
		ModalResetPasswordController
	);
	bind<IRegistrationFormController>(Bindings.RegistrationFormController).to(
		RegistrationFormController
	);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<IModalConfirmAlertController>(Bindings.ModalConfirmAlertController).to(
		ModalConfirmAlertController
	);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IMenuItemsController>(Bindings.MenuItemsController).to(MenuItemsController);
	bind<IComponentsReviewController>(Bindings.ComponentsReviewController).to(
		ComponentsReviewController
	);
	bind<IMyTeamController>(Bindings.MyTeamController).to(MyTeamController);
	bind<IPlayersPoolController>(Bindings.PlayersPoolController).to(PlayersPoolController);
	bind<IPlayerCardController>(Bindings.PlayerCardController).to(PlayerCardController);
	bind<ITeamLineupController>(Bindings.TeamLineupController).to(TeamLineupController);
	bind<ITeamPlayerController>(Bindings.TeamPlayerController).to(TeamPlayerController);
	bind<IJoinCreateLeagueCardController>(Bindings.JoinCreateLeagueCardController).to(
		JoinCreateLeagueCardController
	);
	bind<ITeamBarController>(Bindings.TeamBarController).to(TeamBarController);
	bind<IDashboardController>(Bindings.DashboardController).to(DashboardController);
	bind<IModalAuthorizationController>(Bindings.ModalAuthorizationController).to(
		ModalAuthorizationController
	);
	bind<IInvitedLeaguesController>(Bindings.InvitedLeaguesController).to(InvitedLeaguesController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<ILeagueManageController>(Bindings.LeagueManageController).to(LeagueManageController);
	bind<IModalTeamSavedController>(Bindings.ModalTeamSavedController).to(ModalTeamSavedController);
	bind<IChangePasswordController>(Bindings.ChangePasswordController).to(ChangePasswordController);
	bind<IUpdateNotificationController>(Bindings.UpdateNotificationController).to(
		UpdateNotificationController
	);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<ILeaderboardsController>(Bindings.LeaderboardsController).to(LeaderboardsController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
});
